import React from 'react';
import { Box, Typography, Container, Grid, } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import NavigationDots from '../components/NavigationDots';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const AboutUs = () => {

  const teamMembers = [
    { name: 'Kennedy Smith', title: 'Founder - CEO', imageUrl: '/images/profiles/kennedy.jpeg' },
    { name: 'Christian Baum', title: 'COO', imageUrl: '/images/profiles/christian.jpg' },
    { name: 'Bendegúz Dörnyei', title: 'Head of Customer Success', imageUrl: '/images/profiles/ben.jpg' },
    { name: 'Estelle Gerber', title: 'Media Manager', imageUrl: '/images/profiles/estelle.jpeg' },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{mb: 0, height: "100vh", width: "100%"}}>
    <NavBar />
      <NavigationDots sections={['top', 'founder-story']} />
      <Container maxWidth={false} disableGutters id="top" sx={{ backgroundColor: 'primary.main', color: 'white', height: '90vh', overflow: 'hidden', position: 'relative', display: 'flex', flexDirection: 'column' }}>
      
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/BackgroundPatternBoxes-01.webp)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            opacity: 0.5,
            zIndex: 0,
          }}
        />
        <Container disableGutters id="top" maxWidth={false} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', ml: 0, mr: 0, width: "100%" }}>
            <Box sx={{ textAlign: 'center', padding: {m: '0 300px', xs: 4} }}>
                    <Typography variant="h2" sx={{ mb: 5, p: 0.5, fontFamily: 'Work Sans', fontWeight: 'bold'}}>
                    Our Mission
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 6, p: 1, fontFamily: 'Work Sans', fontSize: {xs: '1.3rem !important'} }}>
                    <strong>SUMMER</strong>STORE strives to make summer storage accessible to college students across the nation. <br></br><br></br>
                    We consistently evolve in tandem with the needs of students, focusing on affordability and convenience to ensure that they can store their belongings without the hassle and high costs associated with traditional storage services.
                   </Typography>
                <Box
                    sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '40px',
                    flexDirection: 'column',
                    backgroundColor: 'none',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    zIndex: 4,
                    }}
                    onClick={() => scrollToSection('founder-story')}
                >
                    <Typography sx={{width: "150%"}}>Learn More</Typography>
                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                </Box>    
              </Box>
        </Container>
      </Container>
      
      <Container
        id="founder-story"
        maxWidth="xl"
        sx={{
          position: 'relative',
          height: 'unset',
          display: 'flex',
          flexWrap: 'noWrap',
          flexDirection: 'column',
          overflow: 'hidden',
          pt: {xs: 10},
          px: {xs: 4}
        }}
      >

       
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap', flexDirection: {xs: "column", md: "row"}, pb: 4}}>
          <Box>
            <Typography variant="h2" sx={{ mb: 5, fontFamily: 'Work Sans', fontWeight: 'bold', fontSize: {xs: "1.5rem !important"}}}>
                Founding Story
            </Typography>
            <Typography variant="body1" lineHeight="2.5" sx={{width: {m: "85%", xs: "95%"}, mb: 3}}>
            In an effort to address the lack of affordable summer storage options for his peers at Yale University, 
            Kennedy Smith ’26 founded SummerStore in the summer of 2022. Witnessing the high costs of existing services, 
            Smith saw an opportunity to provide a more budget-friendly solution. Through strategic planning and student-led moving, 
            SummerStore was able to minimize the per-person cost of summer storage, offering a flat rate per student for the entire summer.
            </Typography>
            <Typography  lineHeight="2.5" sx={{width: "85%"}}> 
              In its inaugural year, SummerStore quickly garnered attention on campus with over 100 students signing up for the service. 
              The company was featured in the Yale Daily News, highlighting its innovative and affordable storage solution for students. <a href="https://yaledailynews.com/blog/2024/04/19/student-creates-yale-only-summer-storage-company/">Read the full article here</a>.
            </Typography>
          </Box>

          
          <Box sx={{ position: 'relative', width: {m: '100%', xs: "80%"}, height: {m: '100%', xs: '300px'}, ml: {m: 4, xs: 0}, m: {xs: 3}, p: {xs: 5}, borderRadius: 5, boxSizing: 'border-box', backgroundColor: "primary.main"}}>
            <Box sx={{ position: 'relative', width: {m: '100%', xs: "100%"}, height: "100%", backgroundImage: 'url(/images/founding_image.webp)', backgroundSize: 'cover', backgroundPositionY: "25%", backgroundRepeat: 'no-repeat', borderRadius: 4 }}>
              
            </Box>
            <Typography 
                variant="caption" 
                sx={{ 
                  position: 'absolute', 
                  bottom: 8, 
                  right: 16, 
                  backgroundColor: 'rgba(0, 0, 0, 0.35)', 
                  color: 'white', 
                  p: 1, 
                  borderRadius: 1 
                }}
              >
                <strong>Kennedy Smith,</strong> Founder and CEO
              </Typography>
          </Box>
        </Box>
        
      </Container>
      <Container maxWidth={false} sx={{backgroundColor: 'primary.main', 
      color:'white', 
      mt: 5,
      py: 8, 
      px: 4,
      overflow: 'hidden', 
      backgroundImage: 'url(/images/background_pattern.webp)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
      }}>
      
        <Typography variant="h4" sx={{ mb: 6, fontWeight: 'bold', textAlign: 'center' }}>
          Meet Our Team
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>

                <Box sx={{width: 175, height: 175, borderRadius: '50%', border: "2px solid #367fae", display:"flex", justifyContent:"center", alignItems: "center"}}>

                  <img src={member.imageUrl} style={{ width: 160, height: 160, borderRadius: '50%', border: "4px solid white" }} alt={member.name} />
                  </Box>
                <Typography variant="h6" sx={{ mt: 2, fontWeight:"bold" }}>{member.name}</Typography>
                <Typography variant="body2">{member.title}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer id="footer" />
    </Box>
  );
};

export default AboutUs;
