import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  MenuItem,
  Container,
  Fade,
  Grid,
  Alert,
  CircularProgress,
  ListSubheader, // Import ListSubheader
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import AppointmentSelector from '../components/AppointmentSelector';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection } from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TermsPopup from '../components/TermsPopup';
import { httpsCallable, getFunctions } from "firebase/functions";

const steps = [
  'Choose Location',
  'Choose Appointment',
  'Create Account',
];

const Registration = () => {

  const functions = getFunctions();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMoveOutDate, setMoveOutDate] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedHallId, setSelectedHallId] = useState('');
  const [selectedHallName, setSelectedHallName] = useState('');
  const [universities, setUniversities] = useState([]);
  const [halls, setHalls] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedContainerLocationId, setSelectedContainerLocationId] = useState('');
  const [openDialog, setOpenDialog] = useState({ terms: false, privacy: false });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsOfService: false, // Added termsOfService
  });
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const universitiesCollection = collection(db, 'universities');
        const universitySnapshot = await getDocs(universitiesCollection);
        const universityList = universitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUniversities(universityList);
      } catch (err) {
        console.error("Error fetching universities:", err);
        setError("Failed to fetch universities. Please try again later.");
      }
    };

    fetchUniversities();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError('');
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError('');
  };

  const handleUniversityChange = async (event) => {
    const universityId = event.target.value;
    setSelectedUniversity(universityId);
    setSelectedHallId('');
    setSelectedHallName('');
    setSelectedContainerLocationId('');

    try {
      const hallsCollection = collection(db, `universities/${universityId}/containerLocations`);
      const hallsSnapshot = await getDocs(hallsCollection);
      const hallList = hallsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHalls(hallList);
    } catch (err) {
      console.error("Error fetching halls:", err);
      setError("Failed to fetch container locations. Please try again later.");
      setHalls([]);
    }
  };

  const handleHallChange = (event) => {
    const hallId = event.target.value;
    setSelectedHallId(hallId);
    setSelectedContainerLocationId(hallId); // Save container location ID

    // Find the hall by ID
    const hall = halls.find((h) => h.id === hallId);

    if (hall) {
      setSelectedHallName(hall.name);
    } else {
      // Handle the case where the hall is not found
      console.error(`Hall with ID ${hallId} not found.`);
      setSelectedHallName('');
      setSelectedContainerLocationId('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email, password, confirmPassword, termsOfService } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 8) {
      setError("Password must contain at least 8 characters");
      return;
    }

    if (firstName.length < 2 || lastName.length < 2) {
      setError("First and last name must contain at least 2 characters");
      return;
    }

    if (!termsOfService) {
      setError("You must agree to the Terms of Service and Privacy Policy to continue.");
      return;
    }

    if (!firstName || !lastName || !email || !password) {
      setError("Please fill out all required fields.");
      return;
    }

    if (!selectedMoveOutDate?.appointmentId || !selectedContainerLocationId) {
      setError("Please select a valid appointment and container location.");
      return;
    }

    setLoading(true);

    try {
      const registerUser = httpsCallable(functions, 'registerUser');

      console.log("RegisterUser Payload: ", {
        firstName,
        lastName,
        email,
        password,
        universityId: selectedUniversity,
        selectedHallId,
        appointmentId: selectedMoveOutDate?.appointmentId,
        containerLocationId: selectedContainerLocationId,
      });

      const response = await registerUser({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        universityId: selectedUniversity,
        selectedHallId: selectedHallId,
        appointmentId: selectedMoveOutDate?.appointmentId,
        containerLocationId: selectedContainerLocationId,
      });

      if (response.data.success) {
        // Log the user in automatically
        await signInWithEmailAndPassword(auth, email, password);

        navigate('/dashboard');
        setLoading(false);
      } else {
        setError(response.data.error || "Unknown error occurred during registration.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error registering user: ", error);
      setError("Error registering user: " + error.message);
      setLoading(false);
    }
  };

  // Helper function to group halls by locationGroup with "Old Campus" first
  const groupHallsByLocationGroup = (halls) => {
    // Group halls by locationGroup
    const grouped = halls.reduce((groups, hall) => {
      const group = hall.locationGroup || 'Other'; // Default to 'Other' if no group
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(hall);
      return groups;
    }, {});

    // Define the desired order of groups, placing "Old Campus" first
    const groupOrder = ['Old Campus', ...Object.keys(grouped).filter(g => g !== 'Old Campus')].sort();

    // Sort halls within each group alphabetically by name
    groupOrder.forEach(group => {
      if (grouped[group]) {
        grouped[group].sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    // Create a sorted grouped object based on groupOrder
    const sortedGrouped = {};
    groupOrder.forEach(group => {
      if (grouped[group]) {
        sortedGrouped[group] = grouped[group];
      }
    });

    return sortedGrouped;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        // Step 0: Choose Location
        const groupedHalls = groupHallsByLocationGroup(halls);

        return (
          <Box sx={{ mx: 3 }}>
            <Typography variant="h5" gutterBottom>
              Choose Location
            </Typography>
            <TextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="university"
              label="Select University"
              value={selectedUniversity}
              onChange={handleUniversityChange}
            >
              {universities.map((university) => (
                <MenuItem key={university.id} value={university.id}>
                  {university.name}
                </MenuItem>
              ))}
            </TextField>
            {selectedUniversity && (
              <>
                {halls.length > 0 ? (
                  <TextField
                    select
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="residentialHall"
                    label="Select Move Out Location"
                    value={selectedHallId} // Updated to use selectedHallId
                    onChange={handleHallChange}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            width: 300, // Increased width for better readability
                          },
                        },
                      },
                    }}
                  >
                    {Object.entries(groupedHalls).flatMap(([groupName, hallsInGroup]) => [
                      <ListSubheader key={`header-${groupName}`}>{groupName}</ListSubheader>,
                      ...hallsInGroup.map((hall) => (
                        <MenuItem key={hall.id} value={hall.id}>
                          {hall.name}
                        </MenuItem>
                      )),
                    ])}
                  </TextField>
                ) : (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    No Move Out Locations available for the selected university.
                  </Typography>
                )}
              </>
            )}
            <Button
              onClick={handleNext}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={!selectedHallId} // Updated to check selectedHallId
            >
              Continue
            </Button>
          </Box>
        );
      case 1:
        // Step 1: Choose Appointment
        return (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3 }}>
              <Container maxWidth="sm">

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: 0 }}>
                  <Typography variant="h5" align="left">
                    Select Move-Out Date
                  </Typography>
                  <Button
                    onClick={handleBack}
                    variant="outlined"
                    color="primary"
                    sx={{ mb: 2 }}
                  >
                    Back
                  </Button>
                </Box>

                <Box >
                  <Typography variant="subtitle2" gutterBottom align="left">
                    Please select your move-out date for the Spring semester. You can make changes to your appointment at any time after registration.
                  </Typography>
                </Box>

                <AppointmentSelector
                  containerLocationID={selectedContainerLocationId}
                  universityID={selectedUniversity}
                  onSelect={(appointment) => {
                    setMoveOutDate({ appointmentId: appointment.id, date: appointment.start });
                    handleNext();
                  }}
                />
              </Container>
            </Box>
          </Box>
        );
      case 2:
        // Step 2: Create Account
        return (
          <Box sx={{ maxWidth: 400, mx: 'auto' }}>

            <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
              Create an account
            </Typography>


            {error && <Alert severity="error">{error}</Alert>}
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="University Email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Residential Hall
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>
                {selectedHallName}
              </Typography>
              <IconButton
                onClick={() => setActiveStep(0)} // Return to step 0 (Choose Location)
                size="small"
                color="primary"
                sx={{ ml: 1 }}
              >
                <EditIcon />
              </IconButton>
            </Box>

            <Box sx={{ mb: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Move Out Date
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>
                {selectedMoveOutDate ? selectedMoveOutDate.date.toDateString() : 'Not Selected'}
              </Typography>
              <IconButton
                onClick={() => setActiveStep(1)} // Return to step 1 (Choose Appointment)
                size="small"
                color="primary"
                sx={{ ml: 1 }}
              >
                <EditIcon />
              </IconButton>
            </Box>


            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="termsOfService"
                  onChange={handleInputChange}
                  checked={formData.termsOfService || false}
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">
                    By checking this box, you agree with our{" "}
                    <Link
                      onClick={() => setOpenDialog({ ...openDialog, terms: true })}
                      sx={{ cursor: "pointer" }}
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={() => setOpenDialog({ ...openDialog, privacy: true })}
                      sx={{ cursor: "pointer" }}
                    >
                      Privacy Policy
                    </Link>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
              }
            />

            <TermsPopup
              open={openDialog.terms}
              onClose={() => setOpenDialog({ ...openDialog, terms: false })}
              filePath="/tos.md"
              title="Terms of Service"
            />

            <TermsPopup
              open={openDialog.privacy}
              onClose={() => setOpenDialog({ ...openDialog, privacy: false })}
              filePath="/pp.md"
              title="Privacy Policy"
            />

            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Create Account"}
            </Button>
            <Button
              fullWidth
              onClick={handleBack}
              variant="outlined"
              color="primary"
              sx={{ mb: 2 }}
            >
              Back
            </Button>

            <Typography variant="body2" align="center">
              Already registered? <Link href="/login">Sign in here</Link>.
            </Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundImage: 'url(/images/background_pattern.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <NavBar />
      <Container
        maxWidth='sm'
        sx={{
          mt: 4,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontFamily="Work Sans" fontWeight="500" variant="h4" gutterBottom>
          Registration
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fade in key={activeStep}>
          <Box
            sx={{
              width: '100%',
              p: 3,
              border: 2,
              borderColor: 'lightgray',
              borderRadius: 2,
              mt: 2,
              backgroundColor: 'white',
            }}
          >
            {renderStepContent(activeStep)}
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

export default Registration;
