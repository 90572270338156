import React, { useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Button,
    Box,
    TextField,
    MenuItem,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    Stack,
    ListSubheader, // Import ListSubheader
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MessageIcon from '@mui/icons-material/Message';
import CallIcon from '@mui/icons-material/Call';
import AppointmentSelector from './AppointmentSelector';

const AppointmentTab = ({
    tabTitle,              // e.g., "Move Out Appointment"
    instructionsTitle,     // e.g., "Move Out Instructions"
    coordinatorName,      // e.g., "John Doe"
    coordinatorEmail,     // e.g., "johndoe@example.com"
    coordinatorPhone,     // e.g., "+1 (555) 123-4567"
    loadingDetails,       // boolean for showing CircularProgress
    appointmentDetails,   // object with start/end times
    onSelectAppointment,  // callback for when user picks an appointment slot
    universityID,         // for <AppointmentSelector />
    containerLocationID,  // for <AppointmentSelector />

    // Location editing props
    locationCollege,      // user’s chosen college (string)
    colleges,             // array of { id, name, locationGroup }
    onSaveLocation,       // callback to save location
    onCollegeChange,      // event handler for college change
}) => {
    // State to manage Location Editing Dialog
    const [openLocationDialog, setOpenLocationDialog] = useState(false);

    // State to manage Appointment Editing Dialog
    const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);

    // Handlers for Location Dialog
    const handleOpenLocationDialog = () => {
        setOpenLocationDialog(true);
    };

    const handleCloseLocationDialog = () => {
        setOpenLocationDialog(false);
    };

    const handleSaveLocation = () => {
        onSaveLocation();
        setOpenLocationDialog(false);
    };

    // Handlers for Appointment Dialog
    const handleOpenAppointmentDialog = () => {
        setOpenAppointmentDialog(true);
    };

    const handleCloseAppointmentDialog = () => {
        setOpenAppointmentDialog(false);
    };

    const handleSelectAppointment = (appointment) => {
        onSelectAppointment(appointment);
        setOpenAppointmentDialog(false);
    };

    // Helper function to get ordinal suffix for a day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Helper function to format the day with ordinal suffix
    const formatDay = (date) => {
        const day = date.getDate();
        const suffix = getOrdinalSuffix(day);
        const month = date.toLocaleString('en-US', { month: 'long' });
        return `${month} ${day}${suffix}`;
    };

    // Helper function to format time in AM/PM
    const formatTime = (date) => {
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).toLowerCase();
    };

    // Helper function to group colleges by 'locationGroup'
    const groupCollegesByLocationGroup = (colleges) => {
        const grouped = colleges.reduce((groups, college) => {
            const group = college.locationGroup || 'Other'; // Default to 'Other' if no group
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(college);
            return groups;
        }, {});

        // Sort groups alphabetically, placing 'Other' last
        const groupNames = Object.keys(grouped).sort((a, b) => {
            if (a === 'Other') return 1;
            if (b === 'Other') return -1;
            return a.localeCompare(b);
        });

        // Sort colleges within each group alphabetically
        groupNames.forEach(group => {
            grouped[group].sort((a, b) => a.name.localeCompare(b.name));
        });

        return { grouped, groupNames };
    };

    // Group colleges by 'locationGroup'
    const { grouped: groupedColleges, groupNames } = groupCollegesByLocationGroup(colleges);

    // Debugging: Log the grouped colleges
    console.log("Grouped Colleges:", groupedColleges);
    console.log("Group Names:", groupNames);

    return (
        <Box>
            <Grid container spacing={3} alignItems="stretch">


                {/* Column 1: Location Info */}
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            flex: 1,
                            borderRadius: 2,
                        }}
                    >
                        {/* Header */}
                        <Box sx={{
                            backgroundImage: 'url(/images/background_pattern.webp)',
                            backgroundColor: 'primary.main',
                            px: 3,
                            py: 2,
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <LocationOnIcon sx={{ fontSize: 48, mb: 1 }} />
                            <Typography variant="h6" gutterBottom>
                                Appointment Location
                            </Typography>
                        </Box>

                        {/* Content */}
                        <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                            <Typography variant="body1" gutterBottom>
                                {locationCollege || 'N/A'}
                            </Typography>
                        </Box>

                        {/* Actions */}
                        <Box sx={{ px: 3, pb: 2 }}>
                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Tooltip title="Edit Location">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EditIcon />}
                                        onClick={handleOpenLocationDialog}
                                        size="medium"
                                    >
                                        Edit
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Box>

                        {/* Location Editing Dialog */}
                        <Dialog
                            open={openLocationDialog}
                            onClose={handleCloseLocationDialog}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>Edit Location</DialogTitle>
                            <DialogContent>
                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        select
                                        label="College"
                                        value={locationCollege}
                                        onChange={onCollegeChange}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                    >
                                        {groupNames.reduce((acc, groupName) => {
                                            acc.push(
                                                <ListSubheader key={`header-${groupName}`}>{groupName}</ListSubheader>
                                            );
                                            acc.push(
                                                ...groupedColleges[groupName].map((college) => (
                                                    <MenuItem key={college.id} value={college.name}>
                                                        {college.name}
                                                    </MenuItem>
                                                ))
                                            );
                                            return acc;
                                        }, [])}
                                    </TextField>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Tooltip title="Cancel">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<CancelIcon />}
                                        onClick={handleCloseLocationDialog}
                                        size="medium"
                                    >
                                        Cancel
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSaveLocation}
                                        size="medium"
                                    >
                                        Save
                                    </Button>
                                </Tooltip>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </Grid>

                {/* Column 2: Appointment Date/Time */}
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            flex: 1,
                            borderRadius: 2,
                        }}
                    >
                        {/* Header */}
                        <Box sx={{
                            backgroundImage: 'url(/images/background_pattern.webp)',
                            backgroundColor: 'primary.main',
                            px: 3,
                            py: 2,
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <CalendarMonthIcon sx={{ fontSize: 48, mb: 1 }} />
                            <Typography variant="h6" gutterBottom>
                                {tabTitle}
                            </Typography>
                        </Box>

                        {/* Content */}
                        <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                            {loadingDetails ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <CircularProgress />
                                </Box>
                            ) : appointmentDetails ? (
                                <>
                                    {/* Format the start and end dates */}
                                    <Typography variant="body1">
                                        <strong>Day:</strong>{' '}
                                        {appointmentDetails.start
                                            ? (appointmentDetails.start.toDate
                                                ? formatDay(appointmentDetails.start.toDate())
                                                : formatDay(new Date(appointmentDetails.start)))
                                            : 'N/A'}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Drop off:</strong>{' '}
                                        {appointmentDetails.start && appointmentDetails.end
                                            ? `${formatTime(appointmentDetails.start.toDate ? appointmentDetails.start.toDate() : new Date(appointmentDetails.start))} to ${formatTime(appointmentDetails.end.toDate ? appointmentDetails.end.toDate() : new Date(appointmentDetails.end))}`
                                            : 'N/A'}
                                    </Typography>
                                </>
                            ) : (
                                <Typography>No appointment scheduled yet.</Typography>
                            )}
                        </Box>

                        {/* Actions */}
                        <Box sx={{ px: 3, pb: 2 }}>
                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Tooltip title={appointmentDetails ? "Update Appointment" : "Schedule Appointment"}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EditIcon />}
                                        onClick={handleOpenAppointmentDialog}
                                        size="medium"
                                    >
                                        {appointmentDetails ? 'Update' : 'Schedule'}
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Box>

                        {/* Appointment Editing Dialog */}
                        <Dialog
                            open={openAppointmentDialog}
                            onClose={handleCloseAppointmentDialog}
                            fullWidth
                            maxWidth="md"
                        >
                            <DialogTitle>{appointmentDetails ? 'Update Appointment' : 'Schedule Appointment'}</DialogTitle>
                            <DialogContent>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" gutterBottom>
                                        Please select a new date/time:
                                    </Typography>
                                    <AppointmentSelector
                                        universityID={universityID}
                                        containerLocationID={containerLocationID}
                                        onSelect={handleSelectAppointment}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Tooltip title="Cancel">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<CancelIcon />}
                                        onClick={handleCloseAppointmentDialog}
                                        size="medium"
                                    >
                                        Cancel
                                    </Button>
                                </Tooltip>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </Grid>

                {/* Column 3: Coordinator Info */}
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            flex: 1,
                            borderRadius: 2,
                        }}
                    >
                        {/* Header */}
                        <Box sx={{
                            backgroundImage: 'url(/images/background_pattern.webp)',
                            backgroundColor: 'primary.main',
                            px: 3,
                            py: 2,
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <PersonOutlineIcon sx={{ fontSize: 48, mb: 1 }} />
                            <Typography variant="h6" gutterBottom>
                                Storage Coordinator
                            </Typography>
                        </Box>

                        {/* Content */}
                        <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                            <Typography variant="body1" gutterBottom>
                                {coordinatorName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Email: {coordinatorEmail || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Phone: {coordinatorPhone || 'N/A'}
                            </Typography>
                        </Box>

                        {/* Actions */}
                        <Box sx={{ px: 3, pb: 2 }}>
                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Tooltip title="Message Coordinator">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<MessageIcon />}
                                        href={`mailto:${coordinatorEmail}`}
                                        disabled={!coordinatorEmail}
                                        size="medium"
                                    >
                                        Message
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Call Coordinator">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CallIcon />}
                                        href={`tel:${coordinatorPhone}`}
                                        disabled={!coordinatorPhone}
                                        size="medium"
                                    >
                                        Call
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </Paper>
                </Grid>

                {/* Instructions (full width) */}
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2, overflow: 'hidden' }}>
                        <Typography variant="h6" gutterBottom>
                            {instructionsTitle}
                        </Typography>
                        <Typography variant="body1">
                            {/* Insert any instructions or guidelines here */}
                            Example: Please arrive 15 minutes early and bring your ID.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );

};

export default AppointmentTab;
