import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection, query, orderBy, getDocs, deleteDoc, } from 'firebase/firestore';
import { useAuth } from './AuthContext'; // Make sure the path is correct
import { useLocation } from 'react-router-dom';

const AnalyticContext = createContext();

export const useAnalytic = () => {
  return useContext(AnalyticContext);
};

const generateActivityDescription = (activityType, additionalData) => {
  switch (activityType) {
    case 'page_view':
      return `Page View: ${additionalData.path}`;
    case 'button_click':
      return `Button clicked: ${additionalData.buttonName}`;
    case 'form_submit':
      return `Form submitted: ${additionalData.formName}`;
    case 'login':
      return `User logged in`;
    case 'logout':
      return `User logged out`;
    default:
      return 'No description available';
  }
};

const AnalyticProvider = ({ children }) => {
  const db = getFirestore();
  const { currentUser, userClaims } = useAuth();
  const location = useLocation();
  const [sessionId, setSessionId] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);
  const [isAdmin] = useState(false);
  const [lastActivityType, setLastActivityType] = useState(null);
  const inactivityTimerRef = useRef(null);

  const INACTIVITY_TIMEOUT = 60000; // Set your session timeout duration here in milliseconds

  const generateSessionId = () => `session_${new Date().getTime()}`;


  // Memoized endSession function
  const endSession = useCallback(async () => {
    if (isAdmin || !sessionId || lastActivityType === 'timed_out') return;
    if (!currentUser) return;

    const uid = currentUser.uid;
    const timestamp = new Date().toISOString();
    const sessionRef = doc(db, `sessions/${uid}`);
    const sessionDoc = await getDoc(sessionRef);

    if (!sessionDoc.exists()) {
      console.log("No session document exists. No action required.");
      return;
    }

    if (sessionDoc.data().status !== 'timed_out') {
      console.log("Logging timed out activity ..");

      const timedOutActivity = {
        activityType: 'timed_out',
        timestamp,
        sessionId: sessionId,
        description: `Session timed out due to inactivity for ${INACTIVITY_TIMEOUT / 1000} seconds.`,
      };

      await setDoc(doc(db, `analytics/users/activityData/${uid}/history/${timestamp}`), timedOutActivity);

      await updateDoc(sessionRef, {
        endTime: timestamp,
        status: 'timed_out',
      });

      localStorage.removeItem('currentSessionId');
      localStorage.setItem('timedOut', true);
      setSessionId(null);
      console.log('Session ended:', sessionId);

      setLastActivityType('timed_out');
    } else {
      console.log("Session already timed out.");
    }
  }, [currentUser, sessionId, lastActivityType, db, isAdmin]);
 
  // Memoized resetInactivityTimer function
  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      endSession();
    }, INACTIVITY_TIMEOUT);
  }, [endSession]);
 // Memoized startNewSession function
 const startNewSession = useCallback(async () => {
  if (isAdmin) return;
  if (!currentUser) return;

  const savedSessionId = localStorage.getItem('currentSessionId');
  const uid = currentUser.uid;
  const newSessionId = savedSessionId ? savedSessionId : generateSessionId();
  const timestamp = new Date().toISOString();

  const sessionRef = doc(db, `sessions/${uid}`);

  await setDoc(sessionRef, {
    sessionID: newSessionId,
    userId: uid,
    startTime: timestamp,
    lastActivityTime: timestamp,
    status: 'active',
  });

  console.log('Session started:', newSessionId);
  localStorage.setItem('currentSessionId', newSessionId);
  localStorage.setItem('timedOut', false);

  setSessionId(newSessionId);
  resetInactivityTimer();
}, [currentUser, isAdmin, db, resetInactivityTimer]);

  const trackUserActivity = useCallback(
    async (activityType, additionalData = {}) => {
      if (isAdmin) return;
    
      if (!currentUser) return;
    
      const uid = currentUser.uid;
      const timestamp = new Date().toISOString();
    
      let savedSessionId = localStorage.getItem('currentSessionId');
      console.log("Saved session id: ", savedSessionId);
    
      if (!savedSessionId) {
        await startNewSession();
        savedSessionId = localStorage.getItem('currentSessionId');
      }
    
      if (savedSessionId) {
        const sessionRef = doc(db, `sessions/${uid}`);
        let sessionDoc = await getDoc(sessionRef);
    
        console.log("Got here 1");
        if (!sessionDoc.exists()) {
          await startNewSession();
          savedSessionId = localStorage.getItem('currentSessionId');
          sessionDoc = await getDoc(sessionRef);
        }
    
        console.log("Got here 2");
        const sessionData = sessionDoc.data();
    
        if (sessionData.status === 'timed_out') {
          await updateDoc(sessionRef, {
            status: 'active',
            lastActivityTime: timestamp,
          });
    
          localStorage.setItem('timedOut', false);
        } else {
          await updateDoc(sessionRef, {
            lastActivityTime: timestamp,
          });
        }
    
        console.log("Logging new activity: ", activityType);
    
        const description = generateActivityDescription(activityType, additionalData);
    
        const activityData = {
          activityType,
          timestamp,
          sessionId: savedSessionId,
          description,
          ...additionalData,
        };
    
        // Save the activity to the user's history
        await setDoc(doc(db, `analytics/users/activityData/${uid}/history/${timestamp}`), activityData);
    
        // Save the activity to the central feed
        const feedCollectionRef = collection(db, 'analytics/feed/data');
        const feedQuery = query(feedCollectionRef, orderBy('timestamp', 'asc'));
    
        const feedSnapshot = await getDocs(feedQuery);
        const feedSize = feedSnapshot.size;
    
        if (feedSize >= 500) {
          // Delete the oldest document if the feed exceeds 500 events
          const oldestDoc = feedSnapshot.docs[0];
          await deleteDoc(doc(db, `analytics/feed/data/${oldestDoc.id}`));
        }
    
        await setDoc(doc(db, `analytics/feed/data/${timestamp}_${uid}`), {
          userId: uid,
          ...activityData
        });
    
        console.log('User activity tracked:', activityData);
    
        setLastActivityType(activityType);
    
        resetInactivityTimer();
      } else {
        console.log("No session id yet!");
      }
    },
    [isAdmin, currentUser, db, startNewSession, resetInactivityTimer] // dependencies
  );
 
 
  const trackPageView = useCallback(async () => {
    const role = userClaims.role;
    const isAdminMaybe = role === 'admin';
    if (isAdminMaybe) return;

    const currentPath = location.pathname;
    if (currentPath !== previousPath) {
      await trackUserActivity('page_view', { path: currentPath });
      setPreviousPath(currentPath);
    }
  }, [userClaims, location.pathname, previousPath, trackUserActivity]);

  useEffect(() => {
    const savedSessionId = localStorage.getItem('currentSessionId');
    const isTimedOut = localStorage.getItem('timedOut');

    if (savedSessionId) {
      setSessionId(savedSessionId);
      resetInactivityTimer();
    } else {
      if (!isTimedOut) {
        startNewSession();
      }
    }

    const handleBeforeUnload = async () => {
      if (isAdmin) return;
      await endSession();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [sessionId, isAdmin, endSession, resetInactivityTimer, startNewSession]);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <AnalyticContext.Provider value={{ trackUserActivity }}>
      {children}
    </AnalyticContext.Provider>
  );
};

export default AnalyticProvider;
