import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, Grid, InputAdornment, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const UniversitySelection = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUniversities = async () => {
      setLoading(true);
      try {
        const universityCollection = collection(db, 'universities');
        const universitySnapshot = await getDocs(universityCollection);
        const universityList = universitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUniversities(universityList);
      } catch (error) {
        console.error("Error fetching universities: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUniversities();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUniversityClick = (universityId) => {
    navigate(`/dashboard/containers/university/${universityId}`);
  };

  const filteredUniversities = universities.filter((university) =>
    university.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Select a University
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search for a university..."
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredUniversities.map((university) => (
            <Grid item xs={12} sm={6} md={4} key={university.id}>
              <Paper
                sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => handleUniversityClick(university.id)}
              >
                <Typography variant="body1">{university.name}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UniversitySelection;
