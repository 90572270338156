// File: src/components/NotificationEditor.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';

// Import your existing editors
import NotificationEditorDetails from '../../components/NotificationEditorDetails';
import NotificationEditorWeb from '../../components/NotificationEditorWeb';
import NotificationEditorText from '../../components/NotificationEditorText';
import NotificationEditorEmail from '../../components/NotificationEditorEmail';
import NotificationEditorScheduler from '../../components/NotificationEditorScheduler';

import { useParams, useNavigate } from 'react-router-dom';
import {
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  collection,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase';

// Import Material-UI Icons
import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WebIcon from '@mui/icons-material/Public'; // Using Public as a web icon
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';

import { isEqual } from 'lodash'; // Import isEqual for deep comparison

const NotificationEditor = () => {
  const { notificationId } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  // =======================
  // State for Details Tab
  // =======================
  const [detailName, setDetailName] = useState('');
  const [detailDescription, setDetailDescription] = useState('');
  const [detailIcon, setDetailIcon] = useState('');

  // Type flags
  const [detailHasWeb, setDetailHasWeb] = useState(false);
  const [detailHasSms, setDetailHasSms] = useState(false);
  const [detailHasEmail, setDetailHasEmail] = useState(false);

  // =======================
  // State for Scheduler Tab
  // =======================
  const [schedules, setSchedules] = useState([]);

  // =======================
  // State for Email Tab
  // =======================
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailComponentsMap, setEmailComponentsMap] = useState({});

  // =======================
  // State for Web Tab
  // =======================
  const [webTitle, setWebTitle] = useState('');
  const [webIcon, setWebIcon] = useState('');
  const [webPrimaryColor, setWebPrimaryColor] = useState('#000000');
  const [webBackgroundColor, setWebBackgroundColor] = useState('#ffffff');
  const [webTextColor, setWebTextColor] = useState('#000000');
  const [webText, setWebText] = useState('');
  const [webButtons, setWebButtons] = useState([]);

  // =======================
  // State for SMS Tab
  // =======================
  const [smsMessage, setSmsMessage] = useState('');

  // =======================
  // Available Triggers State
  // =======================
  const [availableTriggers, setAvailableTriggers] = useState([]);

  // =======================
  // Original Data for Change Detection
  // =======================
  const [originalData, setOriginalData] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  // =======================
  // Confirmation Dialog State
  // =======================
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // =======================
  // Snackbar State for Notifications
  // =======================
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
  });

  // =======================
  // Fetch Notification Details and Initialize `originalData`
  // =======================
  useEffect(() => {
    const fetchDetails = async () => {
      if (notificationId) {
        const docRef = doc(db, 'notifications', notificationId);
        const unsubscribe = onSnapshot(
          docRef,
          async (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              setDetailName(data.detailName || '');
              setDetailDescription(data.detailDescription || '');
              setDetailIcon(data.detailIcon || '');
              setDetailHasWeb(data.detailHasWeb || false);
              setDetailHasSms(data.detailHasSms || false);
              setDetailHasEmail(data.detailHasEmail || false);
              setSchedules(data.schedules || []);

              // Initialize original data
              setOriginalData({
                detailName: data.detailName || '',
                detailDescription: data.detailDescription || '',
                detailIcon: data.detailIcon || '',
                detailHasWeb: data.detailHasWeb || false,
                detailHasSms: data.detailHasSms || false,
                detailHasEmail: data.detailHasEmail || false,
                schedules: data.schedules || [],
                emailSubject: data.emailSubject || '',
                emailBody: data.emailBody || '',
                webTitle: data.webTitle || '',
                webIcon: data.webIcon || '',
                webPrimaryColor: data.webPrimaryColor || '#000000',
                webBackgroundColor: data.webBackgroundColor || '#ffffff',
                webTextColor: data.webTextColor || '#000000',
                webText: data.webText || '',
                webButtons: data.webButtons || [],
                smsMessage: data.smsMessage || '',
              });

              // Load associated data
              // Load Email
              if (data.detailHasEmail) {
                const emailDocRef = doc(db, 'notificationEmail', notificationId);
                const emailSnap = await getDoc(emailDocRef);
                if (emailSnap.exists()) {
                  const emailData = emailSnap.data();
                  setEmailSubject(emailData.subject || '');
                  setEmailBody(emailData.body || '');
                }
              }

              // Load Web
              if (data.detailHasWeb) {
                const webDocRef = doc(db, 'notificationsWeb', notificationId);
                const webSnap = await getDoc(webDocRef);
                if (webSnap.exists()) {
                  const webData = webSnap.data();
                  setWebTitle(webData.title || '');
                  setWebIcon(webData.icon || '');
                  setWebPrimaryColor(webData.primaryColor || '#000000');
                  setWebBackgroundColor(webData.backgroundColor || '#ffffff');
                  setWebTextColor(webData.textColor || '#000000');
                  setWebText(webData.text || '');
                  setWebButtons(webData.buttons || []);
                }
              }

              // Load SMS
              if (data.detailHasSms) {
                const smsDocRef = doc(db, 'textNotifications', notificationId);
                const smsSnap = await getDoc(smsDocRef);
                if (smsSnap.exists()) {
                  const smsData = smsSnap.data();
                  setSmsMessage(smsData.message || '');
                }
              }

              // Load Email Components Map if email is enabled
              if (data.detailHasEmail) {
                const emailComponentsSnapshot = await getDocs(collection(db, 'emailComponents'));
                const mapping = {};
                emailComponentsSnapshot.forEach((docSnap) => {
                  const data = docSnap.data();
                  mapping[data.name.trim()] = data.body;
                });
                setEmailComponentsMap(mapping);
              }
            } else {
              console.warn('Notification does not exist.');
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching notification details:', error);
            setLoading(false);
          }
        );

        // Cleanup listener on unmount
        return () => unsubscribe();
      } else {
        // If creating a new notification, set originalData to initial empty states
        setOriginalData({
          detailName: '',
          detailDescription: '',
          detailIcon: '',
          detailHasWeb: false,
          detailHasSms: false,
          detailHasEmail: false,
          schedules: [],
          emailSubject: '',
          emailBody: '',
          webTitle: '',
          webIcon: '',
          webPrimaryColor: '#000000',
          webBackgroundColor: '#ffffff',
          webTextColor: '#000000',
          webText: '',
          webButtons: [],
          smsMessage: '',
        });
        setLoading(false);
      }
    };

    fetchDetails();
  }, [notificationId]);

  // =======================
  // Fetch Available Triggers
  // =======================
  useEffect(() => {
    const fetchTriggers = async () => {
      try {
        const triggerSnapshot = await getDocs(collection(db, 'triggers'));
        const triggersList = triggerSnapshot.docs.map((doc) => doc.data());
        setAvailableTriggers(triggersList);
      } catch (error) {
        console.error('Error fetching triggers:', error);
      }
    };

    fetchTriggers();
  }, []);

  // =======================
  // Determine if there are unsaved changes
  // =======================
  useEffect(() => {
    if (!originalData) {
      setIsDirty(false);
      return;
    }

    const hasChanges = () => {
      // Compare each state with original data using isEqual for deep comparison
      if (
        detailName !== originalData.detailName ||
        detailDescription !== originalData.detailDescription ||
        detailIcon !== originalData.detailIcon ||
        detailHasWeb !== originalData.detailHasWeb ||
        detailHasSms !== originalData.detailHasSms ||
        detailHasEmail !== originalData.detailHasEmail ||
        !isEqual(schedules, originalData.schedules) ||
        emailSubject !== originalData.emailSubject ||
        emailBody !== originalData.emailBody ||
        webTitle !== originalData.webTitle ||
        webIcon !== originalData.webIcon ||
        webPrimaryColor !== originalData.webPrimaryColor ||
        webBackgroundColor !== originalData.webBackgroundColor ||
        webTextColor !== originalData.webTextColor ||
        webText !== originalData.webText ||
        !isEqual(webButtons, originalData.webButtons) ||
        smsMessage !== originalData.smsMessage
      ) {
        return true;
      }
      return false;
    };

    setIsDirty(hasChanges());
  }, [
    detailName,
    detailDescription,
    detailIcon,
    detailHasWeb,
    detailHasSms,
    detailHasEmail,
    schedules,
    emailSubject,
    emailBody,
    webTitle,
    webIcon,
    webPrimaryColor,
    webBackgroundColor,
    webTextColor,
    webText,
    webButtons,
    smsMessage,
    originalData,
  ]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Define tabs based on enabled notification types with icons
  const tabs = [
    {
      label: 'Details',
      icon: <InfoIcon />,
      component: (
        <NotificationEditorDetails
          detailName={detailName}
          setDetailName={setDetailName}
          detailDescription={detailDescription}
          setDetailDescription={setDetailDescription}
          detailIcon={detailIcon}
          setDetailIcon={setDetailIcon}
          detailHasWeb={detailHasWeb}
          setDetailHasWeb={setDetailHasWeb}
          detailHasSms={detailHasSms}
          setDetailHasSms={setDetailHasSms}
          detailHasEmail={detailHasEmail}
          setDetailHasEmail={setDetailHasEmail}
        />
      ),
    },
  ];

  // Always include the Scheduler tab
  tabs.push({
    label: 'Scheduler',
    icon: <ScheduleIcon />,
    component: (
      <NotificationEditorScheduler
        schedules={schedules}
        setSchedules={setSchedules}
        availableTriggers={availableTriggers} // Pass availableTriggers as a prop
      />
    ),
  });

  if (detailHasWeb) {
    tabs.push({
      label: 'Web Notification',
      icon: <WebIcon />,
      component: (
        <NotificationEditorWeb
          webTitle={webTitle}
          setWebTitle={setWebTitle}
          webIcon={webIcon}
          setWebIcon={setWebIcon}
          webPrimaryColor={webPrimaryColor}
          setWebPrimaryColor={setWebPrimaryColor}
          webBackgroundColor={webBackgroundColor}
          setWebBackgroundColor={setWebBackgroundColor}
          webTextColor={webTextColor}
          setWebTextColor={setWebTextColor}
          webText={webText}
          setWebText={setWebText}
          webButtons={webButtons}
          setWebButtons={setWebButtons}
        />
      ),
    });
  }

  if (detailHasSms) {
    tabs.push({
      label: 'SMS Notification',
      icon: <SmsIcon />,
      component: (
        <NotificationEditorText
          smsMessage={smsMessage}
          setSmsMessage={setSmsMessage}
        />
      ),
    });
  }

  if (detailHasEmail) {
    tabs.push({
      label: 'Email Notification',
      icon: <EmailIcon />,
      component: (
        <NotificationEditorEmail
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          emailComponentsMap={emailComponentsMap}
          setEmailComponentsMap={setEmailComponentsMap}
        />
      ),
    });
  }

  // Adjust activeTab if necessary when tabs change
  useEffect(() => {
    if (activeTab >= tabs.length) {
      setActiveTab(tabs.length - 1);
    }
  }, [tabs.length, activeTab]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // ===========================
  // Universal Save Function
  // ===========================
  const handleSaveAll = async () => {
    try {
      // 1. Save Details
      const detailsData = {
        detailName: detailName.trim(),
        detailDescription: detailDescription.trim(),
        detailIcon: detailIcon.trim(),
        detailHasWeb,
        detailHasSms,
        detailHasEmail,
        schedules, // Assuming schedules are part of details
      };

      if (!notificationId) {
        // Creating a new notification
        const newDocRef = doc(collection(db, 'notifications'));
        await setDoc(newDocRef, detailsData);

        // Save Email, Web, SMS data if enabled
        if (detailHasEmail) {
          const emailDocRef = doc(collection(db, 'notificationEmail'));
          await setDoc(emailDocRef, {
            subject: emailSubject.trim(),
            body: emailBody.trim(),
          });
        }

        if (detailHasWeb) {
          const webDocRef = doc(collection(db, 'notificationsWeb'));
          await setDoc(webDocRef, {
            title: webTitle.trim(),
            icon: webIcon.trim(),
            primaryColor: webPrimaryColor,
            backgroundColor: webBackgroundColor,
            textColor: webTextColor,
            text: webText.trim(),
            buttons: webButtons,
          });
        }

        if (detailHasSms) {
          const smsDocRef = doc(collection(db, 'textNotifications'));
          await setDoc(smsDocRef, {
            message: smsMessage.trim(),
          });
        }

        // Reset originalData to current data
        setOriginalData({
          detailName: detailName.trim(),
          detailDescription: detailDescription.trim(),
          detailIcon: detailIcon.trim(),
          detailHasWeb,
          detailHasSms,
          detailHasEmail,
          schedules,
          emailSubject: emailSubject.trim(),
          emailBody: emailBody.trim(),
          webTitle: webTitle.trim(),
          webIcon: webIcon.trim(),
          webPrimaryColor,
          webBackgroundColor,
          webTextColor,
          webText: webText.trim(),
          webButtons,
          smsMessage: smsMessage.trim(),
        });

        setSnackbar({
          open: true,
          message: 'Notification created successfully!',
          severity: 'success',
        });

        navigate(`dashboard/notifications/edit/${newDocRef.id}/details`);
      } else {
        // Updating an existing notification
        const detailsDocRef = doc(db, 'notifications', notificationId);
        await updateDoc(detailsDocRef, detailsData);

        // Save Email, Web, SMS data if enabled
        if (detailHasEmail) {
          const emailDocRef = doc(db, 'notificationEmail', notificationId);
          await setDoc(emailDocRef, {
            subject: emailSubject.trim(),
            body: emailBody.trim(),
          }, { merge: true });
        }

        if (detailHasWeb) {
          const webDocRef = doc(db, 'notificationsWeb', notificationId);
          await setDoc(webDocRef, {
            title: webTitle.trim(),
            icon: webIcon.trim(),
            primaryColor: webPrimaryColor,
            backgroundColor: webBackgroundColor,
            textColor: webTextColor,
            text: webText.trim(),
            buttons: webButtons,
          }, { merge: true });
        }

        if (detailHasSms) {
          const smsDocRef = doc(db, 'textNotifications', notificationId);
          await setDoc(smsDocRef, {
            message: smsMessage.trim(),
          }, { merge: true });
        }

        // Delete associated documents if the type is disabled
        if (!detailHasEmail && originalData.detailHasEmail) {
          await deleteDoc(doc(db, 'notificationEmail', notificationId)).catch((err) => {
            if (err.code !== 'not-found') {
              console.error('Error deleting email notification:', err);
            }
          });
        }

        if (!detailHasWeb && originalData.detailHasWeb) {
          await deleteDoc(doc(db, 'notificationsWeb', notificationId)).catch((err) => {
            if (err.code !== 'not-found') {
              console.error('Error deleting web notification:', err);
            }
          });
        }

        if (!detailHasSms && originalData.detailHasSms) {
          await deleteDoc(doc(db, 'textNotifications', notificationId)).catch((err) => {
            if (err.code !== 'not-found') {
              console.error('Error deleting SMS notification:', err);
            }
          });
        }

        // Reset originalData to current data
        setOriginalData({
          detailName: detailName.trim(),
          detailDescription: detailDescription.trim(),
          detailIcon: detailIcon.trim(),
          detailHasWeb,
          detailHasSms,
          detailHasEmail,
          schedules,
          emailSubject: emailSubject.trim(),
          emailBody: emailBody.trim(),
          webTitle: webTitle.trim(),
          webIcon: webIcon.trim(),
          webPrimaryColor,
          webBackgroundColor,
          webTextColor,
          webText: webText.trim(),
          webButtons,
          smsMessage: smsMessage.trim(),
        });

        setSnackbar({
          open: true,
          message: 'Notification updated successfully!',
          severity: 'success',
        });

        navigate(-1); // Go back to the previous page
      }
    } catch (error) {
      console.error('Error saving notification:', error);
      // Display an error message to the user
      setSnackbar({
        open: true,
        message: 'Failed to save notification. Please try again.',
        severity: 'error',
      });
    }
  };

  // ===========================
  // Back Button with Unsaved Changes Warning
  // ===========================
  const handleBackButtonClick = () => {
    if (isDirty) {
      setConfirmDialogOpen(true);
    } else {
      navigate(-1);
    }
  };

  const handleConfirmLeave = () => {
    setConfirmDialogOpen(false);
    navigate(-1);
  };

  const handleCancelLeave = () => {
    setConfirmDialogOpen(false);
  };

  // ===========================
  // Handle Snackbar Close
  // ===========================
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 3 }}>
      {/* Back Button */}
      <Button onClick={handleBackButtonClick} variant="outlined" color="primary" sx={{ mb: 2 }}>
        Back
      </Button>

      <Typography variant="h5" sx={{ mb: 2 }}>
        Notification Editor
      </Typography>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Notification Editor Tabs"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={tab.icon}
            iconPosition="start" // Position the icon before the label
          />
        ))}
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {tabs[activeTab]?.component}
      </Box>

      {/* Universal Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAll}
          disabled={!isDirty} // Disable if there's nothing to save
        >
          Save All
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelLeave}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to leave without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLeave} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLeave} color="error">
            Leave Without Saving
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationEditor;







