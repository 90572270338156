import React from 'react';
import { Edges } from '@react-three/drei';

const PhysicsObject = ({ position, dimensions, color, onPointerDown, itemData }) => {

  return (
    <group position={position} onPointerDown={(e) => onPointerDown(e, itemData)}>
      <mesh>
        <boxGeometry args={dimensions} />
        <meshStandardMaterial color={color} />
        <Edges scale={1.0} color="black" linewidth={1}>
          <lineBasicMaterial color="black" linewidth={2} attach="material" />
        </Edges>
      </mesh>

     
    </group>
  );
};

export default PhysicsObject;
