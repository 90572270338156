import React, { useEffect, useState, memo } from 'react';
import { Grid, Paper, Typography, Box, ButtonBase, Button, CircularProgress, ToggleButton, ToggleButtonGroup, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AddCircleOutline, MoreVert,  ViewModule, ViewList, DeleteOutline } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../contexts/NotificationContext'; // Import the notification hook
import { getFirestore, doc, getDoc, collection, getDocs} from 'firebase/firestore';

const MyItems = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotification(); // Destructure addNotification from useNotification
  const { currentUser } = useAuth();
  const db = getFirestore();
  const [items, setItems] = useState([]);
  const [planChosen, setPlanChosen] = useState(false);
  const [maxItems, setMaxItems] = useState(10);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('panel');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      if (!currentUser) return;

      try {
        const itemsCollection = collection(db, `inventoryUsers/${currentUser.uid}/items`);
        const itemsSnapshot = await getDocs(itemsCollection);
        const itemsList = itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemsList);

        if (itemsList.length > 0) {
          setMaxItems(itemsList.length);
        }
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    };

    fetchItems();
  }, [currentUser, db]);

  useEffect(() => {
    const fetchPlan = async () => {
      if (!currentUser) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const selectedPlan = userData.storagePlan;
          if (selectedPlan) {
            const planDoc = await getDoc(doc(db, 'products', selectedPlan));
            if (planDoc.exists()) {
              const planData = planDoc.data();
              setPlanChosen(true);
              setMaxItems(planData.maxItems || items.length); // Set maxItems to the plan's limit or the current number of items
            }
          } else {
            setPlanChosen(false);
          }
        }
      } catch (error) {
        console.error('Error fetching plan: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, [currentUser, db, items.length]);

  const handleAddItemClick = () => {
    if (items.length < maxItems) {
      navigate('/dashboard/add-item');
    }
  };

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleEditItem = (item) => {
    navigate(`/dashboard/edit-item/${item.id}`);
    handleMenuClose();
  };

  const handleViewInfo = (item) => {
    navigate(`/dashboard/item-info/${item.id}`);
    handleMenuClose();
  };

  const handleDeleteItem = async () => {
    /* try {
      await deleteDoc(doc(db, `inventoryUsers/${currentUser.uid}/items`, selectedItem.id));
      setItems(items.filter((i) => i.id !== selectedItem.id));
      addNotification({ status: 'success', message: 'Item deleted successfully!' });
    } catch (error) {
      console.error('Error deleting item: ', error);
    } */

    addNotification({
      status: 'error',
      message: 'The storage term has ended. Editing items is no longer permitted.',
    });
    handleDialogClose();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, flexGrow: 1, position: 'relative' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>My Items</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Use this form to view and report items that will be stored with SummerStore.
          </Typography>
        </Box>
        <Box>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
            sx={{ pt: 1, display: { xs: "none", md: "block" } }}
          >
            <ToggleButton value="panel" aria-label="panel view">
              <ViewModule />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table view">
              <ViewList />
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography noWrap variant="h6" sx={{ mt: 1, mb: 1, ml: { xs: 3, md: 0 } }}><strong> {items.length}/{maxItems}</strong> Items</Typography>
        </Box>
      </Box>

      {viewMode === 'panel' ? (
        <>
          <Grid container spacing={2}>
            {items.map(item => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={item.id}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    textAlign: 'center',
                    borderColor: 'secondary.main',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    position: 'relative',
                    backgroundImage: 'url(/images/background_pattern.webp)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <img src={`/images/items/${item.type.toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.type} style={{ width: 75, height: 75 }} />
                  <Typography 
                    variant="h6" 
                    noWrap 
                    sx={{ 
                      mt: 1, 
                      fontWeight: "bold", 
                      maxWidth: '100%', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap' 
                    }}
                  >
                    {item.name}
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1, mx: 2 }}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} md={6}>
                        <Button 
                          variant="outlined" 
                          sx={{ 
                            color: "white", 
                            borderColor: "white", 
                            backdropFilter: "blur(2px)",
                            width: '100%' // Ensures the button takes up the full width of the grid item
                          }}
                          onClick={() => handleViewInfo(item)}
                          startIcon={<InfoOutlinedIcon />}
                        >
                          Details
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button 
                          variant="contained" 
                          color="secondary"
                          sx={{ 
                            backdropFilter: "blur(2px)",
                            width: '100%' // Ensures the button takes up the full width of the grid item
                          }} 
                          onClick={() => handleEditItem(item)}
                          startIcon={<EditOutlinedIcon />}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <IconButton
                    onClick={(e) => handleMenuOpen(e, item)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
            { items.length < maxItems && [...Array(maxItems - items.length)].map((_, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                <ButtonBase
                  onClick={handleAddItemClick}
                  sx={{ width: '100%', height: '100%', display: 'block' }}
                >
                  <Paper elevation={1} sx={{ p: 2, textAlign: 'center', bgcolor: '#e9ecef' }}>
                    <AddCircleOutline sx={{ fontSize: 40, color: 'grey.700' }} />
                    <Typography variant="body1">Add Item</Typography>
                  </Paper>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleEditItem(selectedItem)}>Edit</MenuItem>
            <MenuItem onClick={handleDialogOpen}>Delete</MenuItem>
          </Menu>

          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">
              Confirm Delete
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
              <DeleteOutline sx={{ fontSize: 60, color: 'gray', mr: 2 }} />
              <Typography variant="body1">
                Are you sure you want to delete {selectedItem ? ('"' + selectedItem.name + '"?') : " this item?"}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteItem} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Name</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Description</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                 
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{item.name}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{item.description}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}> 
                          <Button 
                            variant="outlined" 
                            color="secondary"
                            sx={{ 
                              width: 'auto' // Ensures the button takes up the full width of the grid item
                            }}
                            onClick={() => handleViewInfo(item)}
                            startIcon={<InfoOutlinedIcon />}
                          >
                            Details
                          </Button>
                          <Button 
                            variant="contained" 
                            color="secondary"
                            sx={{ 
                              width: 'auto', // Ensures the button takes up the full width of the grid item
                              ml: 2
                            }} 
                            onClick={() => handleEditItem(item)}
                            startIcon={<EditOutlinedIcon />}
                          >
                            Edit
                          </Button>
                        </Box>
                  </td>
                </tr>
              ))}
              {planChosen && items.length < maxItems && [...Array(maxItems - items.length)].map((_, index) => (
                <tr key={`empty-${index}`} style={{ backgroundColor: (items.length + index) % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                  <td colSpan={5} style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                    <ButtonBase onClick={handleAddItemClick} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <AddCircleOutline sx={{ fontSize: 40, color: 'grey.700', mr: 2 }} />
                      <Typography variant="body1">Add Item</Typography>
                    </ButtonBase>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      )}
      <Typography variant="body2" sx={{ mt: 2 }}>
        Your current plan allows you to store up to {maxItems} items.
      </Typography>
    </Box>
  );
};

export default memo(MyItems);
