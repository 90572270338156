import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Paper, Tabs, Tab, Button, Dialog, DialogActions,
  DialogContent, DialogTitle, TextField, MenuItem, Select, CircularProgress,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import ContainerList from '../../components/ContainerList';
import ContainerLocationList from '../../components/ContainerLocationList';
import { Inventory2, LocationOn } from '@mui/icons-material';

const UniversityViewContainers = () => {
  const navigate = useNavigate();
  const { universityID } = useParams();
  const [value, setValue] = useState(0);
  const [containerLocations, setContainerLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [universityName, setUniversityName] = useState('');

  const [newContainerData, setNewContainerData] = useState({
    containerLocationId: '',
    creationOrder: 0,
    spaceCapacity: 1000,
    spaceReserved: 0,
    universityId: universityID,
  });

  useEffect(() => {
    const fetchContainerLocations = async () => {
      setLoading(true);
      try {
        const containerLocationsRef = collection(db, `universities/${universityID}/containerLocations`);
        const snapshot = await getDocs(containerLocationsRef);
        const locations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContainerLocations(locations);
      } catch (error) {
        console.error('Error fetching container locations:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUniversityName = async () => {
      try {
        const universityDoc = await getDoc(doc(db, 'universities', universityID));
        if (universityDoc.exists()) {
          setUniversityName(universityDoc.data().name || 'Unknown University');
        }
      } catch (error) {
        console.error('Error fetching university name:', error);
      }
    };

    fetchContainerLocations();
    fetchUniversityName();
  }, [universityID]);

  const handleTabChange = (event, newValue) => setValue(newValue);

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialogOpen = () => setOpen(true);

  const handleDialogClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContainerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const selectedLocationId = e.target.value;
    const selectedLocation = containerLocations.find((location) => location.id === selectedLocationId);
    const creationOrder = selectedLocation?.containerIDs?.length || 0;

    setNewContainerData({
      ...newContainerData,
      containerLocationId: selectedLocationId,
      creationOrder,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const docRef = doc(collection(db, 'containers'));
      await setDoc(docRef, { ...newContainerData, id: docRef.id });

      const containerLocationRef = doc(db, `universities/${universityID}/containerLocations/${newContainerData.containerLocationId}`);
      await updateDoc(containerLocationRef, {
        containerIDs: arrayUnion(docRef.id),
      });

      console.log('Container saved successfully!');
      handleDialogClose();
    } catch (error) {
      console.error('Error saving container:', error);
    } finally {
      setSaving(false);
    }
  };

  const containerLocationIDs = containerLocations.map((location) => location.id);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ width: 'auto', mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        Container Dashboard
      </Typography>

      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
          <Tab label="Container Locations" icon={<LocationOn />} iconPosition="start" />
          <Tab label="All Containers" icon={<Inventory2 />} iconPosition="start" />
        </Tabs>
      </Paper>

      <Box sx={{ p: 0, my: 2 }}>
        {value === 0 && (
          <ContainerLocationList
            containerLocations={containerLocations}
            universityID={universityID}
          />
        )}
        {value === 1 && (
          <ContainerList
            containerLocationIDs={containerLocationIDs}
            universityID={universityID}
          />
        )}
      </Box>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Add New Container</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            name="containerLocationId"
            value={newContainerData.containerLocationId}
            onChange={handleLocationChange}
            displayEmpty
          >
            <MenuItem value="" disabled>Select Container Location</MenuItem>
            {containerLocations.map((location) => (
              <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            name="creationOrder"
            label="Creation Order"
            type="number"
            fullWidth
            value={newContainerData.creationOrder}
            onChange={handleInputChange}
            disabled
          />
          <TextField
            margin="dense"
            name="spaceCapacity"
            label="Space Capacity"
            type="number"
            fullWidth
            value={newContainerData.spaceCapacity}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="spaceReserved"
            label="Space Reserved"
            type="number"
            fullWidth
            value={newContainerData.spaceReserved}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="universityId"
            label="University ID"
            type="text"
            fullWidth
            value={newContainerData.universityId}
            onChange={handleInputChange}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" disabled={saving}>Cancel</Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            {saving ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UniversityViewContainers;
