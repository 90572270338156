// File: src/components/NotificationEditorWeb.jsx

import React, { useEffect } from 'react';
import { Box, Typography, TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Button } from '@mui/material';
import IconPicker from 'react-icons-picker';
import NotificationPreview from './NotificationPreview';
import PropTypes from 'prop-types';

const NotificationEditorWeb = ({
    webTitle,
    setWebTitle,
    webIcon,
    setWebIcon,
    webPrimaryColor,
    setWebPrimaryColor,
    webBackgroundColor,
    setWebBackgroundColor,
    webTextColor,
    setWebTextColor,
    webText,
    setWebText,
    webButtons,
    setWebButtons,
}) => {

    // Button methods
    const handleAddButton = () => {
        setWebButtons([
            ...webButtons,
            {
                text: '',
                color: '#000000',
                textColor: '#ffffff',
                type: 'popup',
                value: ''
            }
        ]);
    };

    const handleButtonChange = (index, field, value) => {
        const updatedButtons = [...webButtons];
        updatedButtons[index][field] = value;
        setWebButtons(updatedButtons);
    };

    const handleRemoveButton = (index) => {
        const updatedButtons = webButtons.filter((_, i) => i !== index);
        setWebButtons(updatedButtons);
    };

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Web Notification
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                <IconPicker
                    value={webIcon}
                    onChange={(newIcon) => setWebIcon(newIcon)}
                    showSearch
                    showCategory
                    defaultCategory="all"
                    modalWrapperStyle={{
                        px: 15,
                        zIndex: 9999,
                        maxWidth: '1000px',
                        backgroundColor: 'lightgray',
                        border: '2px gray'
                    }}
                    modalContentWrapperStyle={{
                        padding: 30,
                        maxWidth: '1000px',
                        zIndex: 9999,
                        backgroundColor: 'lightgray',
                        border: '2px black'
                    }}
                />
                <TextField
                    label="Title"
                    value={webTitle}
                    onChange={(e) => setWebTitle(e.target.value)}
                    fullWidth
                    sx={{ ml: 1, mb: 2 }}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                <Box sx={{ mr: 2 }}>
                    <Typography>Primary Color:</Typography>
                    <input
                        type="color"
                        value={webPrimaryColor}
                        onChange={(e) => setWebPrimaryColor(e.target.value)}
                    />
                </Box>

                <Box sx={{ mr: 2 }}>
                    <Typography>Background Color:</Typography>
                    <input
                        type="color"
                        value={webBackgroundColor}
                        onChange={(e) => setWebBackgroundColor(e.target.value)}
                    />
                </Box>

                <Box sx={{ mr: 2 }}>
                    <Typography>Text Color:</Typography>
                    <input
                        type="color"
                        value={webTextColor}
                        onChange={(e) => setWebTextColor(e.target.value)}
                    />
                </Box>
            </Box>

            <TextField
                label="Text Content"
                value={webText}
                onChange={(e) => setWebText(e.target.value)}
                fullWidth
                multiline
                rows={3}
                sx={{ mb: 2 }}
            />

            {/* Button Editor */}
            <Box sx={{ mb: 8 }}>
                {webButtons.map((button, index) => (
                    <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                        <TextField
                            label="Button Text"
                            value={button.text}
                            onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                            fullWidth
                            sx={{ mb: 1 }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Typography sx={{ mr: 2 }}>Button Color:</Typography>
                            <input
                                type="color"
                                value={button.color}
                                onChange={(e) => handleButtonChange(index, 'color', e.target.value)}
                                disabled={button.sameAsPrimary}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={button.sameAsPrimary || false}
                                        onChange={(e) => {
                                            const updatedButtons = [...webButtons];
                                            updatedButtons[index].sameAsPrimary = e.target.checked;
                                            if (e.target.checked) {
                                                updatedButtons[index].color = webPrimaryColor;
                                            }
                                            setWebButtons(updatedButtons);
                                        }}
                                    />
                                }
                                label="Same as Primary"
                                sx={{ ml: 2 }}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography sx={{ mr: 2 }}>Text Color:</Typography>
                            <input
                                type="color"
                                value={button.textColor}
                                onChange={(e) => handleButtonChange(index, 'textColor', e.target.value)}
                            />
                        </Box>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                            <InputLabel>Style</InputLabel>
                            <Select
                                value={button.style || 'outlined'}
                                onChange={(e) => handleButtonChange(index, 'style', e.target.value)}
                            >
                                <MenuItem value="outlined">Outlined</MenuItem>
                                <MenuItem value="contained">Contained</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={button.type}
                                onChange={(e) => handleButtonChange(index, 'type', e.target.value)}
                            >
                                <MenuItem value="popup">Popup</MenuItem>
                                <MenuItem value="link">Link</MenuItem>
                            </Select>
                        </FormControl>
                        {button.type === 'popup' && (
                            <TextField
                                label="Popup Text"
                                value={button.value}
                                onChange={(e) => handleButtonChange(index, 'value', e.target.value)}
                                fullWidth
                                sx={{ mb: 1 }}
                            />
                        )}
                        {button.type === 'link' && (
                            <TextField
                                label="Link URL"
                                value={button.value}
                                onChange={(e) => handleButtonChange(index, 'value', e.target.value)}
                                fullWidth
                                sx={{ mb: 1 }}
                            />
                        )}
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveButton(index)}
                            fullWidth
                        >
                            Remove Button
                        </Button>
                    </Box>
                ))}
                <Button variant="contained" onClick={handleAddButton}>
                    Add Button
                </Button>
            </Box>

            {/* Live Preview */}
            <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Live Preview
                </Typography>
                <NotificationPreview
                    title={webTitle}
                    icon={webIcon}
                    primaryColor={webPrimaryColor}
                    backgroundColor={webBackgroundColor}
                    textColor={webTextColor}
                    text={webText}
                    buttons={webButtons}
                />
            </Box>
        </Box>
    );
};

NotificationEditorWeb.propTypes = {
    webTitle: PropTypes.string.isRequired,
    setWebTitle: PropTypes.func.isRequired,
    webIcon: PropTypes.string.isRequired,
    setWebIcon: PropTypes.func.isRequired,
    webPrimaryColor: PropTypes.string.isRequired,
    setWebPrimaryColor: PropTypes.func.isRequired,
    webBackgroundColor: PropTypes.string.isRequired,
    setWebBackgroundColor: PropTypes.func.isRequired,
    webTextColor: PropTypes.string.isRequired,
    setWebTextColor: PropTypes.func.isRequired,
    webText: PropTypes.string.isRequired,
    setWebText: PropTypes.func.isRequired,
    webButtons: PropTypes.array.isRequired,
    setWebButtons: PropTypes.func.isRequired,
};

export default NotificationEditorWeb;
