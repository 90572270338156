import React from 'react';
import { Container, Paper, Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';

const Support = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Support
      </Typography>

      <Box sx={{ mb: 3 }}>  
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SupportAgentIcon sx={{ mr: 1 }} />
            Support Tickets
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            If you have any issues or need assistance, please submit a support ticket. Our team will get back to you as soon as possible.
          </Typography>
          <List>
            <ListItem button onClick={() => navigate('/dashboard/support/create-ticket')}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Submit a New Ticket" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="View Your Tickets" />
            </ListItem>
          </List>
        </Paper>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <FeedbackIcon sx={{ mr: 1 }} />
            Customer Feedback
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            We value your feedback. Please share your thoughts and suggestions to help us improve our services.
          </Typography>
          <List>
            <ListItem button>
              <ListItemIcon>
                <FeedbackOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Submit Feedback" />
            </ListItem>
          </List>
        </Paper>
      </Box>

      <Box>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <HelpIcon sx={{ mr: 1 }} />
            Help Center
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Browse our help center for FAQs, guides, and tutorials.
          </Typography>
          <List>
            <ListItem button>
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Visit Help Center" />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default Support;
