// File: src/components/UserSearch.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
} from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const UserSearch = ({ onSelectedUsersChange, multiSelect = false }) => {
    const [loading, setLoading] = useState(true);

    // All users fetched from Firestore
    const [allUsers, setAllUsers] = useState([]);
    // Users after applying filters/search/sorting
    const [filteredUsers, setFilteredUsers] = useState([]);

    // Search and filter states
    const [searchText, setSearchText] = useState('');
    const [roleFilter, setRoleFilter] = useState('All');
    const [statusFilter, setStatusFilter] = useState('All');
    // Sorting can be: nameAsc, nameDesc, dateAsc, dateDesc
    const [sortBy, setSortBy] = useState('nameAsc');

    // Keep track of selected user IDs (if multiSelect)
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    // Fetch data from Firestore on mount
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const db = getFirestore();
                const usersRef = collection(db, 'users');
                const snapshot = await getDocs(usersRef);

                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setAllUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    // Filter and sort the user list
    useEffect(() => {
        // If user hasn't typed anything, show no results
        if (!searchText.trim()) {
            setFilteredUsers([]);
            return;
        }

        let newFiltered = [...allUsers];

        // 1. Text Search (by firstName, lastName, email)
        const lowerSearch = searchText.toLowerCase();
        newFiltered = newFiltered.filter((user) => {
            const first = user.firstName?.toLowerCase() || '';
            const last = user.lastName?.toLowerCase() || '';
            const email = user.email?.toLowerCase() || '';
            return (
                first.includes(lowerSearch) ||
                last.includes(lowerSearch) ||
                email.includes(lowerSearch)
            );
        });

        // 2. Role Filter
        if (roleFilter !== 'All') {
            newFiltered = newFiltered.filter((user) => user.role === roleFilter);
        }

        // 3. Status Filter
        if (statusFilter !== 'All') {
            newFiltered = newFiltered.filter((user) => user.status === statusFilter);
        }

        // 4. Sorting
        newFiltered.sort((a, b) => {
            const fullNameA = `${a.firstName || ''}${a.lastName || ''}`.toLowerCase();
            const fullNameB = `${b.firstName || ''}${b.lastName || ''}`.toLowerCase();
            const dateA = a?.registrationDate?.seconds || 0;
            const dateB = b?.registrationDate?.seconds || 0;

            switch (sortBy) {
                case 'nameAsc':
                    return fullNameA.localeCompare(fullNameB);
                case 'nameDesc':
                    return fullNameB.localeCompare(fullNameA);
                case 'dateAsc':
                    return dateA - dateB;
                case 'dateDesc':
                    return dateB - dateA;
                default:
                    return 0;
            }
        });

        setFilteredUsers(newFiltered);
    }, [searchText, roleFilter, statusFilter, sortBy, allUsers]);

    // Whenever selectedUserIds changes, notify the parent (if multiSelect)
    useEffect(() => {
        if (multiSelect && onSelectedUsersChange) {
            onSelectedUsersChange(selectedUserIds);
        }
    }, [selectedUserIds, multiSelect, onSelectedUsersChange]);

    const handleCheckboxChange = (userId) => {
        setSelectedUserIds((prev) => {
            // If user was already selected, unselect them
            if (prev.includes(userId)) {
                return prev.filter((id) => id !== userId);
            } else {
                // Add newly selected user
                return [...prev, userId];
            }
        });
    };

    if (loading) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body1">Loading users...</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Search Users
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Search"
                        placeholder="Search by name or email"
                        variant="outlined"
                        fullWidth
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Grid>

                <Grid item xs={6} md={3}>
                    <FormControl fullWidth>
                        <Select
                            value={roleFilter}
                            onChange={(e) => setRoleFilter(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="All">All Roles</MenuItem>
                            <MenuItem value="Student">Student</MenuItem>
                            <MenuItem value="Faculty">Faculty</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                            {/* More roles... */}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} md={3}>
                    <FormControl fullWidth>
                        <Select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="All">All Statuses</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Archived">Archived</MenuItem>
                            {/* More statuses... */}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                        <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                            <MenuItem value="nameAsc">Name (A-Z)</MenuItem>
                            <MenuItem value="nameDesc">Name (Z-A)</MenuItem>
                            <MenuItem value="dateAsc">Oldest First</MenuItem>
                            <MenuItem value="dateDesc">Newest First</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {!searchText.trim() ? (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        Enter a search term to see matching users.
                    </Typography>
                </Box>
            ) : filteredUsers.length === 0 ? (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography>No matching users found.</Typography>
                </Box>
            ) : (
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* Render a checkbox header only if multiSelect */}
                                    {multiSelect && <TableCell>Select</TableCell>}
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Registration Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.map((user) => {
                                    const isChecked = selectedUserIds.includes(user.id);
                                    return (
                                        <TableRow key={user.id}>
                                            {multiSelect && (
                                                <TableCell>
                                                    <Checkbox
                                                        checked={isChecked}
                                                        onChange={() => handleCheckboxChange(user.id)}
                                                    />
                                                </TableCell>
                                            )}
                                            <TableCell>{user.firstName || ''}</TableCell>
                                            <TableCell>{user.lastName || ''}</TableCell>
                                            <TableCell>{user.email || ''}</TableCell>
                                            <TableCell>{user.role || ''}</TableCell>
                                            <TableCell>{user.status || ''}</TableCell>
                                            <TableCell>
                                                {user.registrationDate?.seconds
                                                    ? new Date(
                                                        user.registrationDate.seconds * 1000
                                                    ).toLocaleString()
                                                    : ''}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Box>
    );
};

export default UserSearch;
