import React, { useEffect, useState, useRef } from 'react';
import { Box, Button } from '@mui/material';

const NavigationDots = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(sections[0]);
  const [dotColors, setDotColors] = useState(new Array(sections.length).fill('white'));

  const dotsRef = useRef([]);

  useEffect(() => {
    const updateDotColors = (sectionId) => {
      const newDotColors = sections.map((section, index) => {
        if (sectionId === 'footer') {
          return 'lightgray';
        } else if (sectionId === 'top') {
          return 'white';
        } else {
          return '#003366'; // Dark blue color for other sections
        }
      });
      setDotColors(newDotColors);
    };
    
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setActiveSection(sectionId);
          updateDotColors(sectionId);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [sections]);

 

  return (
    <Box sx={{ zIndex: 10, position: 'fixed', right: '1rem', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
      {sections.map((section, index) => (
        <Button
          key={index}
          ref={(el) => (dotsRef.current[index] = el)}
          onClick={() => {
            document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
          }}
          sx={{
            minWidth: '0',
            width: '16px',
            height: '16px',
            padding: '0',
            backgroundColor: activeSection === section ? dotColors[index] : 'transparent',
            border: `2px solid ${dotColors[index]}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: activeSection === section ? dotColors[index] : 'rgba(0, 0, 0, 0.1)',
            },
            zIndex: 10,
          }}
        />
      ))}
    </Box>
  );
};

export default NavigationDots;
