import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ContainerList = ({ universityID, containerLocationIDs }) => {
  const [containers, setContainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchContainers = async () => {
      setLoading(true);
      const fetchedContainers = [];
      console.log("university: ", universityID, " Conainerlocations: ", containerLocationIDs);
      try {
        for (const locationID of containerLocationIDs) {
          const locationDocRef = doc(
            db,
            `universities/${universityID}/containerLocations/${locationID}`
          );
          const locationDoc = await getDoc(locationDocRef);

          if (locationDoc.exists() && locationDoc.data().containerIDs) {
            for (const containerID of locationDoc.data().containerIDs) {

            console.log("Containers id: ", containerID);
              const containerDocRef = doc(db, 'containers', containerID);
              const containerDoc = await getDoc(containerDocRef);

              if (containerDoc.exists()) {
                const containerData = containerDoc.data();
                fetchedContainers.push({
                  id: containerDoc.id,
                  locationName: locationDoc.data().name || 'Unknown',
                  ...containerData,
                });
              }
            }
          }
        }

        setContainers(fetchedContainers);
      } catch (error) {
        console.error('Error fetching containers:', error);
      } finally {
        setLoading(false);
      }
    };

    if (universityID && containerLocationIDs.length > 0) {
      fetchContainers();
    }
  }, [universityID, containerLocationIDs]);

  const calculatePercentage = (used, total) => {
    return total > 0 ? (used / total) * 100 : 0;
  };

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        All Containers
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Container Location</TableCell>
                  <TableCell>Space Capacity</TableCell>
                  <TableCell>Space Used</TableCell>
                  <TableCell>Space Reserved</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((container) => (
                  <TableRow key={container.id}>
                    <TableCell>{container.locationName}</TableCell>
                    <TableCell>{container.spaceCapacity} cubic ft</TableCell>
                    <TableCell>
                      <Tooltip title={`${calculatePercentage(container.actualSpace, container.spaceCapacity).toFixed(2)}%`}>
                        <Box sx={{ width: `${calculatePercentage(container.actualSpace, container.spaceCapacity)}%`, backgroundColor: '#4caf50', height: '20px', borderRadius: '4px' }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {container.spaceReserved} cubic ft
                    </TableCell>
                    <TableCell>
                      <Link to={`/dashboard/containers/container/${container.id}`}>View Contents</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={containers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default ContainerList;
