// File: src/components/NotificationEditorEmail.jsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Stack, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';

import NotificationPreviewEmail from './NotificationPreviewEmail';
import NotificationTemplatesPopup from './NotificationTemplatesPopup';
import NotificationComponentsPopup from './NotificationComponentsPopup';

// Import icons from MUI icons-material
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';

const NotificationEditorEmail = ({
    emailSubject,
    setEmailSubject,
    emailBody,
    setEmailBody,
    emailComponentsMap,
    setEmailComponentsMap,
}) => {
    const { notificationId } = useParams();
    const navigate = useNavigate();

    const [templatesOpen, setTemplatesOpen] = useState(false);
    const [componentsOpen, setComponentsOpen] = useState(false);

    // Fetch the email notification data on page load.
    useEffect(() => {
        const fetchNotification = async () => {
            if (notificationId) {
                try {
                    const docRef = doc(db, 'notificationEmail', notificationId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setEmailSubject(data.subject || '');
                        setEmailBody(data.body || '');
                    }
                } catch (err) {
                    console.error('Error fetching email notification:', err);
                }
            }
        };

        fetchNotification();
    }, [notificationId, setEmailSubject, setEmailBody]);

    // Fetch components list on page load
    useEffect(() => {
        const fetchComponentsMap = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'emailComponents'));
                const mapping = {};
                querySnapshot.forEach((docSnap) => {
                    const data = docSnap.data();
                    mapping[data.name.trim()] = data.body;
                });
                setEmailComponentsMap(mapping);
            } catch (err) {
                console.error('Error fetching components:', err);
            }
        };

        fetchComponentsMap();
    }, [setEmailComponentsMap]);

    return (
        <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {notificationId ? 'Edit Email Notification' : 'Create New Email Notification'}
                </Typography>
            </Box>

            {/* Two separate buttons for managing Templates and Components */}
            <Stack direction="row" sx={{ mb: 2, justifyContent: 'space-between' }}>
                <TextField
                    label="Enter email subject..."
                    value={emailSubject}
                    size="small"
                    onChange={(e) => setEmailSubject(e.target.value)}
                    sx={{ width: 500 }}
                />
                <Box sx={{ display: "flex", flexDirection: 'row', height: "100%" }}>
                    <Button
                        variant="outlined"
                        onClick={() => setTemplatesOpen(true)}
                        sx={{ mr: 2 }}
                    >
                        <ViewQuiltOutlinedIcon sx={{ mr: 1 }} />
                        Templates
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setComponentsOpen(true)}
                    >
                        <ExtensionIcon sx={{ mr: 1 }} />
                        Components
                    </Button>
                </Box>
            </Stack>

            {/* Split layout: HTML editor on the left and Live Preview on the right */}
            <Box sx={{ display: 'flex', gap: 4 }}>
                {/* LEFT: HTML Editor using React Ace */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Email Editor
                    </Typography>
                    <AceEditor
                        mode="html"
                        theme="github"
                        name="emailHtmlEditor"
                        value={emailBody}
                        onChange={(newValue) => setEmailBody(newValue)}
                        fontSize={14}
                        width="100%"
                        height="1000px"
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        setOptions={{ useWorker: false }}
                        style={{ marginBottom: '24px' }}
                    />
                </Box>

                {/* RIGHT: Live Preview */}
                <Box
                    sx={{
                        flex: 1,
                        borderRadius: 2,
                        p: 2,
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Live Preview
                    </Typography>
                    <NotificationPreviewEmail subject={emailSubject} body={emailBody} components={emailComponentsMap} />
                </Box>
            </Box>

            {/* Popups for Templates and Components */}
            <NotificationTemplatesPopup
                open={templatesOpen}
                onClose={() => setTemplatesOpen(false)}
                onTemplateSelect={(template) => {
                    setEmailSubject(template.subject || '');
                    setEmailBody(template.body || '');
                    setTemplatesOpen(false);
                }}
            />
            <NotificationComponentsPopup
                open={componentsOpen}
                onClose={() => setComponentsOpen(false)}
                currentBody={emailBody}
                onComponentInsert={(placeholder) => {
                    setEmailBody((prev) => prev + "\n" + placeholder);
                    setComponentsOpen(false);
                }}
                onComponentEdit={(htmlContent) => {
                    // Replace the current email body with the component's HTML content.
                    setEmailBody(htmlContent);
                }}
                // This callback will update the componentsMap state when components are updated.
                onComponentsChange={setEmailComponentsMap}
            />
        </Box>
    );
};

export default NotificationEditorEmail;
