import React, { useState } from 'react';
import {
  Box, Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Tooltip, Menu, Checkbox, ListItemText, Divider,
  InputAdornment, TablePagination, Popover, useTheme
} from '@mui/material';
import { Add, Edit, Delete, FilterAltOutlined, FilterAlt, GetApp, ArrowDropDown, ArrowDropUp, Search, ClearAll } from '@mui/icons-material';
import { DateRange } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const salesData = [
  { id: 1, customerName: 'John Doe', invoiceId: 'X12345', transactionId: 'TX12345', description: 'Storage for winter clothes', university: 'Yale University', amount: '$200', date: '2024-07-01' },
  { id: 2, customerName: 'Jane Smith', invoiceId: 'X12345', transactionId: 'TX12346', description: 'Storage for books and personal items', university: 'Harvard University', amount: '$150', date: '2024-07-02' },
  { id: 3, customerName: 'Jane Smith', invoiceId: 'X12345', transactionId: 'TX1246', description: 'Storage', university: 'Harvard University', amount: '$150', date: '2024-07-02' },
  // Add more sample data as needed
];

const SalesDashboard = () => {
  const [sales, setSales] = useState(salesData);
  const [open, setOpen] = useState(false);
  const [editingSale, setEditingSale] = useState(null);
  const [filterAnchors, setFilterAnchors] = useState({});
  const [filters, setFilters] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickOpen = () => {
    setEditingSale(null);
    setOpen(true);
  };

  const handleEditClick = (sale) => {
    navigate(`/dashboard/sales-dashboard/${sale.transactionId}`);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingSale(null);
  };

  const handleSave = () => {
    // Logic to save the sale
    setOpen(false);
    setEditingSale(null);
  };

  const handleExport = () => {
    // Logic to export sales data
  };

  const handleNullifyClick = () => {
    // Logic to export sales data
  };

  const handleFilterClick = (event, column) => {
    setFilterAnchors({ ...filterAnchors, [column]: event.currentTarget });
  };

  const handleFilterClose = (column) => {
    setFilterAnchors({ ...filterAnchors, [column]: null });
  };

  const handleFilterChange = (column, value) => {
    const columnFilters = filters[column] || [];
    const newFilters = columnFilters.includes(value)
      ? columnFilters.filter((v) => v !== value)
      : [...columnFilters, value];
    setFilters({ ...filters, [column]: newFilters });
  };

  const handleFilterSearchChange = (event) => {
    setFilterSearch(event.target.value);
  };

  const handleSelectAll = (column) => {
    const columnValues = sales.map((row) => row[column])
      .filter((value, index, self) => self.indexOf(value) === index);
    setFilters({ ...filters, [column]: columnValues });
  };

  const handleDeselectAll = (column) => {
    setFilters({ ...filters, [column]: [] });
  };

  const handleSort = (column) => {
    const isAsc = sortOrder[column] === 'asc';
    setSortOrder({ [column]: isAsc ? 'desc' : 'asc' });
    const sortedSales = [...sales].sort((a, b) => {
      if (a[column] < b[column]) return isAsc ? -1 : 1;
      if (a[column] > b[column]) return isAsc ? 1 : -1;
      return 0;
    });
    setSales(sortedSales);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = (data) => {
    return data.filter((row) =>
      Object.keys(filters).every((column) =>
        filters[column]?.length ? filters[column].includes(row[column]) : true
      ) &&
      (!dateRange[0].startDate || new Date(row.date) >= dateRange[0].startDate) &&
      (!dateRange[0].endDate || new Date(row.date) <= dateRange[0].endDate)
    );
  };

  const filteredSales = applyFilters(sales);

  const handleDateRangeClick = (event) => {
    setDateAnchorEl(event.currentTarget);
    setShowDatePicker(true);
  };

  const handleDateRangeClose = () => {
    setDateAnchorEl(null);
    setShowDatePicker(false);
  };

  const clearFilters = () => {
    setFilters({});
    setSortOrder({});
    setFilterSearch('');
    setDateRange([{
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
      key: 'selection'
    }]);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sales Dashboard
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Recent Sales</Typography>
        <Box>
          <Tooltip title="Export Sales Data">
            <IconButton onClick={handleExport}>
              <GetApp />
            </IconButton>
          </Tooltip>
          {Object.keys(filters).some(column => filters[column]?.length) && (
            <Button
              variant="outlined"
              startIcon={<ClearAll />}
              onClick={clearFilters}
              sx={{ mr: 2 }}
            >
              Clear Filters
            </Button>
          )}
          <Button variant="outlined" onClick={handleDateRangeClick} 
              sx={{ mr: 2 }}>
            Select Date Range
          </Button>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleClickOpen}>
            Create New Sale
          </Button>
        </Box>
      </Box>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          
          <Popover
            open={showDatePicker}
            anchorEl={dateAnchorEl}
            onClose={handleDateRangeClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
            />
          </Popover>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['customerName', 'invoiceId', 'transactionId', 'description', 'university', 'amount', 'date'].map((column) => (
                  <TableCell key={column}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>{column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Typography>
                      <IconButton
                        size="small"
                        onClick={(e) => handleFilterClick(e, column)}
                        sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                      >
                        {
                            filters[column]?.length ? <FilterAlt /> : <FilterAltOutlined sx={{strokeWidth: 2}}/>
                        }
                    
                      </IconButton>
                      <Menu
                        anchorEl={filterAnchors[column]}
                        open={Boolean(filterAnchors[column])}
                        onClose={() => handleFilterClose(column)}
                      >
                        <Box sx={{ p: 1 }}>
                          <TextField
                            variant="outlined"
                            placeholder="Search..."
                            fullWidth
                            value={filterSearch}
                            onChange={handleFilterSearchChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Button onClick={() => handleSelectAll(column)}>Select All</Button>
                            <Button onClick={() => handleDeselectAll(column)}>Deselect All</Button>
                          </Box>
                          <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                            {sales.map((row) => row[column])
                              .filter((value, index, self) => self.indexOf(value) === index)
                              .filter((value) => value.toString().toLowerCase().includes(filterSearch.toLowerCase()))
                              .map((value) => (
                                <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                  <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                  <ListItemText primary={value} />
                                </MenuItem>
                              ))}
                          </Box>
                          <Divider />
                          <Button onClick={() => handleSort(column)}>
                            {sortOrder[column] === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
                            Sort
                          </Button>
                        </Box>
                      </Menu>
                    </Box>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => (
                <TableRow key={sale.id} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}>
                  <TableCell>{sale.customerName}</TableCell>
                  <TableCell>{sale.invoiceId}</TableCell>
                  <TableCell>{sale.transactionId}</TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      {sale.description}
                    </Box>
                  </TableCell>
                  <TableCell>{sale.university}</TableCell>
                  <TableCell>{sale.amount}</TableCell>
                  <TableCell>{sale.date}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(sale)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleNullifyClick(sale.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredSales.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingSale ? 'Edit Sale' : 'Create New Sale'}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Customer Name" fullWidth defaultValue={editingSale?.customerName || ''} />
          <TextField margin="dense" label="Transaction ID" fullWidth defaultValue={editingSale?.transactionId || ''} />
          <TextField margin="dense" label="Description" fullWidth defaultValue={editingSale?.description || ''} />
          <TextField margin="dense" label="University" fullWidth defaultValue={editingSale?.university || ''} />
          <TextField margin="dense" label="Amount" fullWidth defaultValue={editingSale?.amount || ''} />
          <TextField margin="dense" label="Date" type="date" fullWidth defaultValue={editingSale?.date || ''} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {editingSale ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SalesDashboard;
