import React from 'react';
import { Box, Typography } from '@mui/material';

/**
 * Replaces placeholders of the form {{ componentName }}
 * with the corresponding HTML from the componentsMap.
 *
 * @param {string} rawHtml - The raw email HTML that may include placeholders.
 * @param {Object} componentsMap - An object mapping component names to HTML strings.
 *                                 e.g. { footer: '<tr style="...">...</tr>' }
 * @returns {string} The processed HTML with all placeholders replaced.
 */
const replaceComponents = (rawHtml, componentsMap = {}) => {
    
    // Replace each occurrence with the corresponding value from componentsMap, or keep the original if not found.
    return rawHtml.replace(/\{\{\s*(\w+)\s*\}\}/g, (match, componentName) => {
        return componentsMap[componentName] || match;
    });
};


const NotificationPreviewEmail = ({ subject, body, components }) => {
    // Process the raw body and replace any {{ componentName }} placeholders
    const processedBody = replaceComponents(body || '<p>Email body preview will appear here.</p>', components);

    return (
        <Box>
            <Typography variant="subtitle1" sx={{
                mb: 1, p: 2, backgroundColor: "white",
                    border: '1px solid #ddd',
                borderRadius: 1, }}>
                {subject || 'Email Subject Preview'}
            </Typography>
            <Box
                sx={{
                    p: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    backgroundColor: '#fff',
                    minHeight: 200
                }}
                dangerouslySetInnerHTML={{ __html: processedBody }}
            />
        </Box>
    );
};

export default NotificationPreviewEmail;
