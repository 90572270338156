import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Paper,
    Button,
    CircularProgress,
    Alert,
    TextField
} from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";

// Helper to convert Firestore Timestamps or strings to JS Dates
function convertToDate(value) {
    if (!value) return null;
    if (value instanceof Timestamp) {
        return value.toDate();
    }
    if (value instanceof Date) {
        return value;
    }
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
}

const UniversityEditor = () => {
    const { universityID } = useParams();
    const navigate = useNavigate();

    const [universityData, setUniversityData] = useState({
        name: "",
        earlyRegistrationDeadline: null,
        lateRegistrationDeadline: null,
        moveOutDates: [], // Array of JS Date objects
        moveInDates: [],  // Array of JS Date objects
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const fetchUniversityData = async () => {
            setLoading(true);
            setError(null);

            try {
                const universityRef = doc(db, "universities", universityID);
                const universitySnap = await getDoc(universityRef);

                if (universitySnap.exists()) {
                    const data = universitySnap.data();

                    setUniversityData({
                        name: data.name || "",
                        earlyRegistrationDeadline: convertToDate(data.earlyRegistrationDeadline),
                        lateRegistrationDeadline: convertToDate(data.lateRegistrationDeadline),
                        moveOutDates: Array.isArray(data.moveOutDates)
                            ? data.moveOutDates.map((val) => convertToDate(val)).filter(Boolean)
                            : [],
                        moveInDates: Array.isArray(data.moveInDates)
                            ? data.moveInDates.map((val) => convertToDate(val)).filter(Boolean)
                            : [],
                    });
                } else {
                    setError("University not found.");
                }
            } catch (err) {
                console.error("Error fetching university data:", err);
                setError("Failed to load university data.");
            } finally {
                setLoading(false);
            }
        };

        fetchUniversityData();
    }, [universityID]);

    const handleSave = async () => {
        setSaving(true);
        setError(null);
        setSuccess(false);

        try {
            const universityRef = doc(db, "universities", universityID);

            // Prepare data to store in Firestore as Timestamps
            const updatedData = {
                name: universityData.name,
                earlyRegistrationDeadline: universityData.earlyRegistrationDeadline
                    ? Timestamp.fromDate(universityData.earlyRegistrationDeadline)
                    : null,
                lateRegistrationDeadline: universityData.lateRegistrationDeadline
                    ? Timestamp.fromDate(universityData.lateRegistrationDeadline)
                    : null,
                moveOutDates: universityData.moveOutDates.map((date) =>
                    Timestamp.fromDate(date)
                ),
                moveInDates: universityData.moveInDates.map((date) =>
                    Timestamp.fromDate(date)
                ),
            };

            await updateDoc(universityRef, updatedData);

            setSuccess(true);
        } catch (err) {
            console.error("Error saving university data:", err);
            setError("Failed to save university data.");
        } finally {
            setSaving(false);
        }
    };

    // Handle date-range selection from the Calendars
    const handleDateChange = (field, dates) => {
        // React-Calendar returns either a single Date or [startDate, endDate] if `selectRange` is true.
        const normalized = Array.isArray(dates) ? dates : [dates];
        setUniversityData((prev) => ({
            ...prev,
            [field]: normalized.filter(Boolean),
        }));
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ mt: 5, mx: "auto", maxWidth: 600 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    // Helper to transform a Date object into YYYY-MM-DD for <TextField type="date" />
    const formatDateField = (dateObj) => {
        return dateObj ? dateObj.toISOString().slice(0, 10) : "";
    };

    // Whenever the user types/picks a date, parse or clear it
    const parseDateField = (value) => {
        return value ? new Date(value) : null;
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ width: "auto", mb: 2 }}
            >
                Back
            </Button>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h4">University Editor</Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                        Editing:
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {universityData.name || "No name"}
                    </Typography>
                </Box>
            </Box>

            <Paper sx={{ p: 3, mt: 2 }}>
                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        University information saved successfully!
                    </Alert>
                )}

                {/* University Name - editable */}
                <Typography variant="h6" gutterBottom>
                    University Name
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={universityData.name}
                    onChange={(e) =>
                        setUniversityData((prev) => ({ ...prev, name: e.target.value }))
                    }
                    sx={{ mb: 3 }}
                />

                {/* Early Registration Deadline - editable */}
                <Typography variant="h6">Early Registration Deadline</Typography>
                <TextField
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateField(universityData.earlyRegistrationDeadline)}
                    onChange={(e) => {
                        const newDate = parseDateField(e.target.value);
                        setUniversityData((prev) => ({
                            ...prev,
                            earlyRegistrationDeadline: newDate,
                        }));
                    }}
                    sx={{ mb: 3 }}
                />

                {/* Late Registration Deadline - editable */}
                <Typography variant="h6">Late Registration Deadline</Typography>
                <TextField
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateField(universityData.lateRegistrationDeadline)}
                    onChange={(e) => {
                        const newDate = parseDateField(e.target.value);
                        setUniversityData((prev) => ({
                            ...prev,
                            lateRegistrationDeadline: newDate,
                        }));
                    }}
                    sx={{ mb: 3 }}
                />

                {/* Move-Out Period - date range */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Move-Out Period
                </Typography>
                <Calendar
                    selectRange
                    // If user has 0 or 1 dates, pass them accordingly;
                    // if user has 2, pass them as an array.
                    value={
                        universityData.moveOutDates.length > 1
                            ? [universityData.moveOutDates[0], universityData.moveOutDates[1]]
                            : universityData.moveOutDates[0] || null
                    }
                    onChange={(dates) => handleDateChange("moveOutDates", dates)}
                />
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Selected Move-Out Dates:{" "}
                    {universityData.moveOutDates.length > 0
                        ? universityData.moveOutDates
                            .map((date) => new Date(date).toLocaleDateString())
                            .join(" - ")
                        : "None"}
                </Typography>

                {/* Move-In Period - date range */}
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Move-In Period
                </Typography>
                <Calendar
                    selectRange
                    value={
                        universityData.moveInDates.length > 1
                            ? [universityData.moveInDates[0], universityData.moveInDates[1]]
                            : universityData.moveInDates[0] || null
                    }
                    onChange={(dates) => handleDateChange("moveInDates", dates)}
                />
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Selected Move-In Dates:{" "}
                    {universityData.moveInDates.length > 0
                        ? universityData.moveInDates
                            .map((date) => new Date(date).toLocaleDateString())
                            .join(" - ")
                        : "None"}
                </Typography>

                {/* Action Buttons */}
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                    <Button variant="outlined" onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={saving}
                    >
                        {saving ? <CircularProgress size={24} /> : "Save"}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default UniversityEditor;
