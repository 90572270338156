import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl
} from '@mui/material';
import { Inbox, ShoppingCart, AccessTime } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs} from 'firebase/firestore';

const UserDetail = () => {

  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('items');
  const [tabLoading, setTabLoading] = useState(false);
  const [viewMode, setViewMode] = useState('activity'); // Toggle between 'activity' and 'session'
  const [timeRange, setTimeRange] = useState('today'); // Default to 'today'
  const [activities, setActivities] = useState([]);
  const [groupedActivities, setGroupedActivities] = useState({});
  const navigate = useNavigate();


  const getStartDate = (range) => {
    const now = new Date();
    switch (range) {
      case 'today':
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
      case '3days':
        return new Date(now.setDate(now.getDate() - 3));
      case 'week':
        return new Date(now.setDate(now.getDate() - 7));
      case 'month':
        return new Date(now.setMonth(now.getMonth() - 1));
      default:
        return new Date(0); // All time
    }
  };

  const groupBySessionId = useCallback(async (activities, userId) => {
    const db = getFirestore(); // Ensure this reference is inside the callback
    const sessionRef = doc(db, `sessions/${userId}`);
    let sessionDoc = await getDoc(sessionRef);
    const activeSessionId = sessionDoc.exists() ? sessionDoc.data().sessionID : "alwaysInactive";
    console.log("Active session id: ", activeSessionId);

    const grouped = activities.reduce((acc, activity) => {
      const sessionId = activity.sessionId || 'unknown';
      if (!acc[sessionId]) {
        acc[sessionId] = {
          activities: [],
          startTime: new Date(activity.timestamp),
          endTime: new Date(activity.timestamp),
          active: (activeSessionId === sessionId ? true : false),
        };
      }

      acc[sessionId].activities.push(activity);

      const activityTime = new Date(activity.timestamp);
      if (activityTime < acc[sessionId].startTime) {
        acc[sessionId].startTime = activityTime;
      }
      if (activityTime > acc[sessionId].endTime) {
        acc[sessionId].endTime = activityTime;
      }

      return acc;
    }, {});

    // Sort activities in each session by timestamp in descending order
    for (const sessionId in grouped) {
      grouped[sessionId].activities.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    return grouped;
  }, []);
  
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
    setUser(prevUser => ({ ...prevUser, activityHistory: null })); // Clear previous data to trigger a refetch
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setUser(prevUser => ({ ...prevUser, activityHistory: null })); // Clear previous data to trigger a refetch
  };
  
  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchUser = async () => {
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const regDate = userData.registrationDate ? new Date(userData.registrationDate.seconds * 1000).toLocaleDateString() : 'N/A';
        userData.registrationDate = regDate;

        setUser(userData);
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  
  useEffect(() => {
    if (user && tab && !user[tab]) {
      setTabLoading(true);
      const fetchTabData = async () => {
        const db = getFirestore();
        const startDate = getStartDate(timeRange);

        if (tab === 'activityHistory') {
          const activityCollection = collection(db, `analytics/users/activityData/${userId}/history`);
          const activityDocs = await getDocs(activityCollection);
          const fetchedActivities = activityDocs.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(activity => new Date(activity.timestamp) >= startDate)
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by timestamp in descending order

          if (viewMode === 'activity') {
            setActivities(fetchedActivities);
          } else if (viewMode === 'session') {
            const grouped = await groupBySessionId(fetchedActivities, userId);
            setGroupedActivities(grouped);
          }
        } else {
          const tabCollection = tab;
          const tabQuery = collection(db, `users/${userId}/${tabCollection}`);
          const tabDocs = await getDocs(tabQuery);
          const tabData = tabDocs.docs.map(doc => doc.data());

          setUser(prevUser => ({
            ...prevUser,
            [tab]: tabData
          }));
        }
        setTabLoading(false);
      };

      fetchTabData();
    }
  }, [tab, user, userId, viewMode, timeRange, groupBySessionId]);


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userId || !user) {
    return <Typography>Unable to load. Try refreshing, or check user ID.</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Button variant="outlined" color="primary" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>User Details</Typography>
      <Grid container spacing={3}>  
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Profile Information</Typography>
            <Box sx={{ mb: 4 }}>
              <Typography variant="body1"><strong>First Name:</strong> {user.firstName}</Typography>
              <Typography variant="body1"><strong>Last Name:</strong> {user.lastName}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
              <Typography variant="body1"><strong>University:</strong> {user.university}</Typography>
              <Typography variant="body1"><strong>Registration Date:</strong> {user.registrationDate}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {user.status}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} sx={{ flexGrow: 1 }}>
          <Paper sx={{ mb: 2, flexGrow: 1, width: '100%' }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="purchases or items" variant="fullWidth">
              <Tab icon={<Inbox />} label="Items" value="items" iconPosition="start" />
              <Tab icon={<ShoppingCart />} label="Purchases" value="purchases" iconPosition="start" />
              <Tab icon={<AccessTime />} label="Activity" value="activityHistory" iconPosition="start" />
            </Tabs>
          </Paper>
          <FormControl sx={{ mb: 2, minWidth: 120 }}>
            <Select value={viewMode} onChange={handleViewModeChange}>
              <MenuItem value="activity">View by Activity</MenuItem>
              <MenuItem value="session">View by Session</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ mb: 2, minWidth: 120, ml: 2 }}>
            <Select value={timeRange} onChange={handleTimeRangeChange}>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="3days">Last 3 Days</MenuItem>
              <MenuItem value="week">Last Week</MenuItem>
              <MenuItem value="month">Last Month</MenuItem>
              <MenuItem value="all">All Time</MenuItem>
            </Select>
          </FormControl>
          {tabLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress />
            </Box>
          ) : viewMode === 'activity' && activities.length > 0 ? (
            <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Activity</Typography>
              <TableContainer sx={{ flexGrow: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Activity Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activities.map((activity) => (
                      <TableRow key={activity.id}>
                        <TableCell>{activity.activityType || 'Unknown'}</TableCell>
                        <TableCell>
                          {activity.timestamp
                            ? new Date(activity.timestamp).toLocaleString()  // Parse ISO string to Date object
                            : 'Unknown Date'}
                        </TableCell>
                        <TableCell>{activity.description || 'No details available'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : viewMode === 'session' && Object.keys(groupedActivities).length > 0 ? (
            <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Sessions</Typography>
              {Object.keys(groupedActivities).map((sessionId) => {
                const session = groupedActivities[sessionId];
                const totalDuration = Math.abs(session.endTime - session.startTime);
                const durationHours = Math.floor(totalDuration / (1000 * 60 * 60));
                const durationMinutes = Math.floor((totalDuration % (1000 * 60 * 60)) / (1000 * 60));
          
                return (
                  <Box key={sessionId} sx={{ mb: 3 }}>
                    <Typography variant="subtitle1">
                      Session ID: {sessionId} ({session.activities.length} activities)
                    </Typography>
                    <Typography variant="body2">
                      Start Time: {session.startTime.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      End Time: {session.active ? 'Ongoing' : session.endTime.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      Total Duration: {durationHours}h {durationMinutes}m
                    </Typography>
                    <Typography variant="body2" color={session.active ? 'green' : 'black'}>
                      Status: {session.active ? 'Active' : 'Inactive'}
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Activity Type</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {session.activities.map((activity) => (
                            <TableRow key={activity.id}>
                              <TableCell>{activity.activityType || 'Unknown'}</TableCell>
                              <TableCell>
                                {activity.timestamp
                                  ? new Date(activity.timestamp).toLocaleString()
                                  : 'Unknown Date'}
                              </TableCell>
                              <TableCell>{activity.description || 'No details available'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                );
              })}
            </Paper>
          ) : (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography>No activity found for this user.</Typography>
            </Box>
          )}
          
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;
