import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Paper, TextField, Button, Stepper, Step, StepLabel, Grid, Tabs, Tab, InputAdornment, MenuItem, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useNotification } from '../../contexts/NotificationContext';
import Search from '@mui/icons-material/Search';

const loadAllConfigs = async () => {
  try {
    const itemConfigsModule = await import('../../config/items.json');
    const itemConfigs = itemConfigsModule.default || itemConfigsModule;
    if (!Array.isArray(itemConfigs)) {
      throw new TypeError('itemConfigs is not an array');
    }
    const items = {};
    itemConfigs.forEach((itemConfig) => {
      items[itemConfig.id] = itemConfig;
    });
    return items;
  } catch (error) {
    console.error('Error loading item configurations:', error);
    return {};
  }
};

const AddItem = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const db = getFirestore();

  const { addNotification } = useNotification();
  const [activeStep, setActiveStep] = useState(0);
  const [itemType, setItemType] = useState('');
  const [itemConfig, setItemConfig] = useState(null);
  const [allConfigs, setAllConfigs] = useState({});
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [supplies, setSupplies] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [promptAnswers, setPromptAnswers] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newItemType, setNewItemType] = useState(null);
  const [newItemTypeLabel, setNewItemTypeLabel] = useState(null);
  const [activeInfo, setActiveInfo] = useState({});
  const [hasStoragePlan, setHasStoragePlan] = useState(false);

  const [itemNameError, setItemNameError] = useState(false);
  const [itemDescriptionError, setItemDescriptionError] = useState(false);
  const [dimensionsError, setDimensionsError] = useState({ length: false, width: false, height: false });

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const loadConfigs = async () => {
      const configs = await loadAllConfigs();
      setAllConfigs(configs);
    };
    loadConfigs();
  }, []);

  useEffect(() => {
    if (itemType) {
      const config = allConfigs[itemType];
      setItemConfig(config);
      if (config && config.lockedDimensions) {
        setDimensions(config.lockedDimensions);
      }
      if (config?.prompts) {
        const defaultAnswers = config.prompts.reduce((answers, prompt, index) => {
          if (prompt.defaultAnswer !== undefined) {
            answers[index] = prompt.defaultAnswer === 'true';
          }
          return answers;
        }, {});
        setPromptAnswers(defaultAnswers);
      }
    }
  }, [itemType, allConfigs]);

  useEffect(() => {
      const hasStoragePlan = async () => {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().storagePlan) {
          setHasStoragePlan(true);
        }
        setHasStoragePlan(false);
    };
    hasStoragePlan();
  }, [currentUser.uid, db]);

  const steps = useMemo(() => {
    const baseSteps = ['Select Item Type', 'Item Details', 'Supplies'];
    if (itemConfig?.prompts && itemConfig.prompts.length > 0) {
      baseSteps.push('Packing Instructions');
    }
    baseSteps.push('Confirmation');
    return baseSteps;
  }, [itemConfig]);

  const handleNext = () => {
    if (activeStep === 1 && !validateItemData()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    navigate('/dashboard/items');
  };

  const validateItemData = () => {
    let isValid = true;

    if (!itemType) {
      addNotification({ status: 'error', message: 'Item type is required.' });
      isValid = false;
    }
    if (!itemName.trim()) {
      setItemNameError(true);
      addNotification({ status: 'error', message: 'Item name is required.' });
      isValid = false;
    } else {
      setItemNameError(false);
    }
    if (!itemDescription.trim()) {
      setItemDescriptionError(true);
      addNotification({ status: 'error', message: 'Item description is required.' });
      isValid = false;
    } else {
      setItemDescriptionError(false);
    }
    if (!itemConfig?.lockedDimensions) {
      let dimensionsError = { length: false, width: false, height: false };
      if (!dimensions.length || !dimensions.width || !dimensions.height) {
        addNotification({ status: 'error', message: 'Item dimensions are required.' });
        dimensionsError = { length: !dimensions.length, width: !dimensions.width, height: !dimensions.height };
        isValid = false;
      } else if (isNaN(dimensions.length) || isNaN(dimensions.width) || isNaN(dimensions.height)) {
        addNotification({ status: 'error', message: 'Dimensions must be numbers.' });
        dimensionsError = {
          length: isNaN(dimensions.length),
          width: isNaN(dimensions.width),
          height: isNaN(dimensions.height),
        };
        isValid = false;
      }
      setDimensionsError(dimensionsError);
    }
    return isValid;
  };

  const handleSave = async () => {
    if (!validateItemData()) {
      return;
    }
  
    setIsSaving(true);
    try {
      const functions = getFunctions();
      const addItem = httpsCallable(functions, 'addItem');
      

      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();

      const response = await addItem({
        itemType,
        itemName,
        itemDescription,
        dimensions,
        supplies,
        promptAnswers,
        userId: currentUser.uid,
        containerLocationId: userData.assignedContainerLocationId,
      });
  
      if (response.data.success) {
        addNotification({ status: 'success', message: 'Item added successfully!' });
        navigate('/dashboard/items');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      addNotification({ status: 'error', message: error.message });
    } finally {
      setIsSaving(false);
    }
  };
  

  /* const hasAvailableSpace = async (container, itemDimensions) => {
    const { length: containerLength, width: containerWidth, height: containerHeight } = container.dimensions;
    const { length: itemLength, width: itemWidth, height: itemHeight } = itemDimensions;

    const items = await getItems(container.id);

    const occupiedSpaces = items.map((item) => ({
      x: item.location.x,
      y: item.location.y,
      z: item.location.z,
      length: item.dimensions.length,
      width: item.dimensions.width,
      height: item.dimensions.height,
    }));

    const doOverlap = (box1, box2) => {
      const xOverlap = box1.x < box2.x + box2.length && box1.x + box1.length > box2.x;
      const yOverlap = box1.y < box2.y + box2.width && box1.y + box1.width > box2.y;
      const zOverlap = box1.z < box2.z + box2.height && box1.z + box1.height > box2.z;
      return xOverlap && yOverlap && zOverlap;
    };

    const fitsInContainer = (x, y, z) => {
      return x + itemLength <= containerLength && y + itemWidth <= containerWidth && z + itemHeight <= containerHeight;
    };

    for (let x = 0; x <= containerLength - itemLength; x++) {
      for (let y = 0; y <= containerHeight - itemHeight; y++) {
        for (let z = 0; z <= containerWidth - itemWidth; z++) {
          const newItem = { x, y, z, length: itemLength, width: itemWidth, height: itemHeight };
          const overlaps = occupiedSpaces.some((occupied) => doOverlap(newItem, occupied));
          if (!overlaps && fitsInContainer(x, y, z)) {
            return { x, y, z };
          }
        }
      }
    }
    return null;
  }; */

  /* async function handleInsufficientSpace(assignedContainer, userData) {
    const previousContainerID = assignedContainer.id;
    const newAssignedContainer = await createNewContainer();
    const containerCustomerDoc = doc(db, `containers/${previousContainerID}/customers/${userData.uid}`);
    const containerCustomerSnapshot = await getDoc(containerCustomerDoc);
    if (containerCustomerSnapshot.exists()) {
      await deleteDoc(containerCustomerDoc);
    }
    await updateDoc(doc(db, `users/${userData.uid}`), {
      assignedContainerLocationId: newAssignedContainer.containerLocationId,
    });
    return newAssignedContainer;
  } */

  /* async function handleNonExistentAssignedContainer(containersList, itemDimensions, userData) {
    let assignedContainer = containersList.find((container) => hasAvailableSpace(container, itemDimensions));
    if (!assignedContainer) {
      assignedContainer = await createNewContainer();
    }
    await updateDoc(doc(db, `users/${userData.uid}`), {
      assignedContainerLocationId: assignedContainer.containerLocationId,
    });
    return assignedContainer;
  }
 */
  
  /* async function createNewContainer() {
    const containersCollection = collection(db, 'containers');
    const newContainerData = {
      spaceCapacity: 1000,
      spaceReserved: 0,
      items: [],
      dimensions: { length: 100, width: 100, height: 100 },
    };
    const newContainerRef = await addDoc(containersCollection, newContainerData);
    const newContainerSnapshot = await getDoc(newContainerRef);
    return { id: newContainerRef.id, containerLocationId: newContainerRef.id, ...newContainerSnapshot.data() };
  } */

  /* const getItems = async (containerId) => {
    if (!containerId) {
      return [];
    }
    const customersCollection = collection(db, `containers/${containerId}/customers`);
    const customersSnapshot = await getDocs(customersCollection);
    const items = [];
    for (const customerDoc of customersSnapshot.docs) {
      const customerId = customerDoc.id;
      const customerItemsCollection = collection(db, `containers/${containerId}/customers/${customerId}/items`);
      const customerItemsSnapshot = await getDocs(customerItemsCollection);
      customerItemsSnapshot.forEach((itemDoc) => {
        const itemData = itemDoc.data();
        items.push(itemData);
      });
    }
    return items;
  }; */

  const handlePromptAnswerChange = (questionIndex, answer) => {
    setPromptAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionIndex]: answer };
      const prompt = itemConfig.prompts[questionIndex];
      if (prompt && prompt.next_steps && prompt.next_steps[answer]) {
        const newStep = prompt.next_steps[answer].find((step) => step.newItemType);
        if (newStep) {
          const newItemType = newStep.newItemType;
          const newItemTypeConfig = allConfigs[newItemType];
          if (newItemTypeConfig) {
            setNewItemType(newItemType);
            setNewItemTypeLabel(newItemTypeConfig.label);
            setDialogOpen(true);
            return prevAnswers;
          }
        }
      }
      return newAnswers;
    });
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm && newItemType) {
      setItemType(newItemType);
      setNewItemType(null);
      setNewItemTypeLabel(null);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleShowInfo = (id, infoType) => {
    setActiveInfo({ id, infoType });
  };

  const handleHideInfo = () => {
    setActiveInfo({});
  };

  const categories = ['All', ...new Set(Object.values(allConfigs).map((config) => config.category))];

  const filteredItems = Object.values(allConfigs).filter(
    (config) =>
      (selectedCategory === 0 || config.category === categories[selectedCategory]) &&
      config.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (selectedCategory === 0 || selectedCategory === 1) {
    const summerStoreBoxIndex = filteredItems.findIndex((config) => config.id === 'summerstore_box');
    if (summerStoreBoxIndex !== -1) {
      const [summerStoreBoxItem] = filteredItems.splice(summerStoreBoxIndex, 1);
      filteredItems.unshift(summerStoreBoxItem);
    }
  }

  const getPackingInstructions = () => {
    if (!itemConfig?.prompts) return [];
    return itemConfig.prompts.flatMap((prompt, index) => {
      const answer = promptAnswers[index];
      if (answer !== undefined && prompt.next_steps && prompt.next_steps[answer]) {
        return prompt.next_steps[answer].map((step) => step.instruction);
      }
      return [];
    });
  };

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Add Item
      </Typography>
      <Paper sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Browse Items
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Tabs value={selectedCategory} onChange={handleCategoryChange}>
                {categories.map((category, index) => (
                  <Tab key={category} label={category} />
                ))}
              </Tabs>
              <Box>
                <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
                  Cancel
                </Button>
              </Box>
            </Box>
            <TextField
              variant="outlined"
              placeholder="Search for an item..."
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Grid container spacing={2}>
              {filteredItems.map((config) => (
                <Grid item xs={12} sm={6} md={4} key={config.id}>
                  <Paper
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        transform: activeInfo.id === config.id ? 'translateX(-100%)' : 'translateX(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          py: 3,
                        }}
                      >
                        <img src={config.icon} alt={config.label} style={{ width: 80, height: 80 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
                        {config.label}
                      </Typography>
                      <Box sx={{ display: 'flex', mt: 1, mb: 2 }}>
                        <Button
                          variant="outlined"
                          sx={{ m: 1 }}
                          onClick={() => handleShowInfo(config.id, 'maxDimensions')}
                        >
                          Max Dimensions
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ m: 1 }}
                          onClick={() => {
                            setItemType(config.id);
                            handleNext();
                          }}
                        >
                          Select
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff',
                        transform: activeInfo.id === config.id ? 'translateX(0)' : 'translateX(100%)',
                        transition: 'transform 0.3s ease-in-out',
                        padding: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ mt: 1 }}>
                        Maximum Dimensions
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {config.maxDimensions}
                      </Typography>
                      <Button variant="contained" onClick={handleHideInfo} sx={{ mt: 2 }}>
                        Back
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Item Details</Typography>
            <TextField
              label="Item Name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              error={itemNameError}
              helperText={itemNameError && "Item name is required."}
            />
            <TextField
              label="Item Description"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              error={itemDescriptionError}
              helperText={itemDescriptionError && "Item description is required."}
            />
            {itemConfig?.prompts && (
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Important Questions
              </Typography>
            )}
            {itemConfig?.prompts?.map((prompt, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body1">{prompt.question}</Typography>
                {prompt.type === 'boolean' && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={prompt.defaultAnswer === 'true'}
                        onChange={(e) => handlePromptAnswerChange(index, e.target.checked)}
                      />
                    }
                    label={prompt.defaultAnswer === 'true' ? 'Yes' : 'No'}
                  />
                )}
              </Box>
            ))}
            {!itemConfig?.lockedDimensions && (
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Item Dimensions
              </Typography>
            )}
            {!itemConfig?.lockedDimensions && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <TextField
                  label="Length (inches)"
                  value={dimensions.length}
                  onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
                  sx={{ mb: 2 }}
                  error={dimensionsError.length}
                  helperText={dimensionsError.length && "Length is required and must be a number."}
                />
                <TextField
                  label="Width (inches)"
                  value={dimensions.width}
                  onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
                  sx={{ mb: 2 }}
                  error={dimensionsError.width}
                  helperText={dimensionsError.width && "Width is required and must be a number."}
                />
                <TextField
                  label="Height (inches)"
                  value={dimensions.height}
                  onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
                  sx={{ mb: 2 }}
                  error={dimensionsError.height}
                  helperText={dimensionsError.height && "Height is required and must be a number."}
                />
              </Box>
            )}
          </Box>
        )}
        {activeStep === 2 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Supplies</Typography>
            <TextField
              label="Additional Supplies"
              select
              value={supplies}
              onChange={(e) => setSupplies(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Box>
        )}
        {steps.includes('Packing Instructions') && activeStep === 3 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Packing Instructions</Typography>
            {getPackingInstructions().map((instruction, index) => (
              <Typography key={index} variant="body1" sx={{ mb: 2 }}>
                {instruction}
              </Typography>
            ))}
          </Box>
        )}
        {activeStep === steps.length - 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Confirmation</Typography>
            <Typography variant="body1">
              <strong>Item Type:</strong> {itemConfig?.label}
            </Typography>
            <Typography variant="body1">
              <strong>Item Name:</strong> {itemName}
            </Typography>
            <Typography variant="body1">
              <strong>Item Description:</strong> {itemDescription}
            </Typography>
            {itemConfig?.lockedDimensions && (
              <Typography variant="body1">
                <strong>Dimensions:</strong> {dimensions.length}x{dimensions.width}x{dimensions.height}
              </Typography>
            )}
            <Typography variant="body1">
              <strong>Additional Supplies:</strong> {supplies ? 'Yes' : 'No'}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ mr: 2 }}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button variant="outlined" onClick={handleBack} sx={{ mr: 2 }}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
              {isSaving ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will change the item type from <strong>{itemConfig?.label}</strong> to <strong>{newItemTypeLabel}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddItem;
