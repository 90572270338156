import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import {
  Storage as StorageIcon,
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const AdminDashboard = () => {
  const [storageData, setStorageData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  useEffect(() => {
    // Fetch notifications data
    /* setNotifications([
      { id: 1, message: 'New user registered', date: '09/01/2024' },
      { id: 2, message: 'Item pickup deadline approaching', date: '09/15/2024' },
    ]); */

    // Mock data for analytics
    setStorageData([
      { name: 'Jan', occupied: 80 },
      { name: 'Feb', occupied: 85 },
      { name: 'Mar', occupied: 90 },
      { name: 'Apr', occupied: 95 },
      { name: 'May', occupied: 100 },
      { name: 'Jun', occupied: 105 },
    ]);

    setUserData([
      { name: 'Jan', users: 50 },
      { name: 'Feb', users: 55 },
      { name: 'Mar', users: 60 },
      { name: 'Apr', users: 65 },
      { name: 'May', users: 70 },
      { name: 'Jun', users: 75 },
    ]);

    setRevenueData([
      { name: 'Jan', revenue: 2000 },
      { name: 'Feb', revenue: 2500 },
      { name: 'Mar', revenue: 3000 },
      { name: 'Apr', revenue: 3500 },
      { name: 'May', revenue: 4000 },
      { name: 'Jun', revenue: 4500 },
    ]);
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
            Overview
          </Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
                  <StorageIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                  <Typography variant="h6">Storage Containers</Typography>
                  <Typography variant="body1">120/150 Occupied</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
                  <PeopleIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                  <Typography variant="h6">Registered Users</Typography>
                  <Typography variant="body1">200</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
                  <AssessmentIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                  <Typography variant="h6">Total Orders</Typography>
                  <Typography variant="body1">75</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
                  <AttachMoneyIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                  <Typography variant="h6">Revenue</Typography>
                  <Typography variant="body1">$12,500</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
            Analytics
          </Typography>
          <Paper sx={{ p: 2 }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={storageData}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="occupied" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              User Growth
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={userData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="users" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Revenue Over Time
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={revenueData}
                  dataKey="revenue"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {revenueData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
