// NotificationPreview.jsx
import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { IconPickerItem } from 'react-icons-picker';

/**
 * Renders a live preview of the notification using the provided props.
 */
const NotificationPreview = ({
  title,
  icon,
  primaryColor,
  backgroundColor,
  textColor,
  text,
  buttons = [],
}) => {
  const [openDialog, setOpenDialog] = useState(null);

  // Handle button clicks; demonstrates how to handle "popup" or "link"
  const handleButtonClick = (button) => {
    if (button.type === 'popup') {
      // Open a dialog with the button's value as content
      setOpenDialog(button.value);
    } else if (button.type === 'link') {
      // Open the link in a new tab
      window.open(button.value, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: 3,
        px: 5,
        borderRadius: 2,
        border: `3px solid ${primaryColor || '#000'}`,
        backgroundColor: backgroundColor || '#f5f5f5',
        color: textColor || '#000',
        textAlign: 'center',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        width: '100%', // adjust as needed
      }}
    >
      {/* Icon */}
      <IconPickerItem
        value={icon || 'FaUsers'}
        size={60}
        color={primaryColor || '#000'}
      />

      {/* Title */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>

      {/* Text Content */}
      <Typography variant="body2" sx={{ mt: 1 }}>
        {text}
      </Typography>

      {/* Dynamic Buttons */}
      <Box sx={{ mt: 2 }}>
        {buttons.map((button, idx) => (
          <Button
            key={idx}
            onClick={() => handleButtonClick(button)}
            variant={button.style || 'outlined'}
            sx={{
              m: 1,
              borderColor: button.color || primaryColor,
              backgroundColor: button.style === 'contained' ? (button.color || primaryColor) : 'transparent',
              color:
                button.style === 'contained'
                  ? (button.textColor || '#fff')
                  : (button.color || primaryColor),
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            {button.text || 'Button'}
          </Button>
        ))}
      </Box>

      {/* Simple Popup Dialog for 'popup' buttons */}
      <Dialog open={Boolean(openDialog)} onClose={() => setOpenDialog(null)}>
        <DialogTitle>Popup</DialogTitle>
        <DialogContent>
          <Typography>{openDialog}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationPreview;
