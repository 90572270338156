import React from 'react';
import { Box, Typography, Button, Modal, Grid, useTheme } from '@mui/material';
import { Inventory, CalendarToday, Moving, Timeline } from '@mui/icons-material';

const WelcomePopup = ({ open, onClose, name }) => {
  const theme = useTheme();

  // Updated data array for the 4 boxes:
  const dashboardFeatures = [
    {
      title: 'Manage Items',
      description: 'Register and keep track of your items',
      icon: <Inventory sx={{ fontSize: { xs: 30, sm: 50 }, color: 'primary.main' }} />,
    },
    {
      title: 'Hire Movers',
      description: 'Get professional moving help',
      icon: <Moving sx={{ fontSize: { xs: 30, sm: 50 }, color: 'primary.main' }} />,
    },
    {
      title: 'Appointments',
      description: 'Schedule or modify your moving appointments',
      icon: <CalendarToday sx={{ fontSize: { xs: 30, sm: 50 }, color: 'primary.main' }} />,
    },
    {
      title: 'Service Timeline',
      description: 'View important upcoming deadlines',
      icon: <Timeline sx={{ fontSize: { xs: 30, sm: 50 }, color: 'primary.main' }} />,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      {/* Position this Box relatively so our SVG can absolutely overlay it */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: { xs: 3, sm: 7 },
          borderRadius: 2,
          width: '90%',
          maxWidth: 900,
          maxHeight: '85vh',
          overflowY: 'auto',
          textAlign: 'center',
          backgroundImage: 'url(/images/background_pattern.webp)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          // Make sure we can overlay the animated border
          position: 'relative',
        }}
      >

        {/* Heading */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: { xs: '1.3rem', sm: '2.125rem' },
          }}
        >
          <strong>Welcome {name}!</strong>
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '0.875rem', sm: '1rem' },
            mb: 3,
          }}
        >
          Here’s a quick look at the features available from your <strong>dashboard</strong>:
        </Typography>

        {/* Features */}
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {dashboardFeatures.map((feature) => (
            <Grid item xs={12} sm={6} md={3} key={feature.title}>
              <Box
                sx={{
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: { xs: 'row', sm: 'column' },
                  alignItems: 'center',
                  justifyContent: { xs: 'flex-start', sm: 'center' },
                  p: 1.3,
                  textAlign: { xs: 'left', sm: 'center' },
                }}
              >
                {feature.icon}
                <Box sx={{ ml: { xs: 4, sm: 0 } }}>
                  <Typography variant="h6" sx={{ mb: { xs: 0.1, sm: 1 } }}>
                    <strong>{feature.title}</strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Button */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={onClose}
            size="xl"
            sx={{ maxWidth: 300, p: 1.5 }}
          >
            <strong>Got it!</strong>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WelcomePopup;
