import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AppointmentEditor = () => {
  const { universityID, containerLocationID, appointmentID } = useParams();
  const navigate = useNavigate();

  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Helper function to format Date to "YYYY-MM-DDTHH:MM" for datetime-local input
  const formatDateToInput = (date) => {
    const pad = (num) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Fetch appointment details
  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const appointmentRef = doc(
          db,
          `universities/${universityID}/containerLocations/${containerLocationID}/appointments`,
          appointmentID
        );
        const appointmentDoc = await getDoc(appointmentRef);
        if (appointmentDoc.exists()) {
          const data = appointmentDoc.data();
          setAppointment({
            ...data,
            start: formatDateToInput(data.start.toDate()),
            end: formatDateToInput(data.end.toDate()),
          });
        } else {
          console.error('Appointment not found.');
          setSnackbar({
            open: true,
            message: 'Appointment not found.',
            severity: 'error',
          });
        }
      } catch (error) {
        console.error('Error fetching appointment:', error);
        setSnackbar({
          open: true,
          message: 'Error fetching appointment details.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAppointment();
  }, [universityID, containerLocationID, appointmentID]);

  const handleInputChange = (field, value) => {
    setAppointment((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (!appointment) return;

    setSaving(true);
    try {
      const appointmentRef = doc(
        db,
        `universities/${universityID}/containerLocations/${containerLocationID}/appointments`,
        appointmentID
      );

      // Convert start and end back to Date objects
      const updatedAppointment = {
        ...appointment,
        start: new Date(appointment.start),
        end: new Date(appointment.end),
      };

      await updateDoc(appointmentRef, updatedAppointment);

      setSnackbar({
        open: true,
        message: 'Appointment updated successfully!',
        severity: 'success',
      });

      navigate(-1); // Go back to the previous page after saving
    } catch (error) {
      console.error('Error updating appointment:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update appointment.',
        severity: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!appointment) {
    return (
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 5 }}>
        Appointment not found.
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Edit Appointment
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          label="Title"
          value={appointment.title}
          onChange={(e) => handleInputChange('title', e.target.value)}
        />
        <TextField
          fullWidth
          label="Start Time"
          type="datetime-local"
          value={appointment.start}
          onChange={(e) => handleInputChange('start', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="End Time"
          type="datetime-local"
          value={appointment.end}
          onChange={(e) => handleInputChange('end', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="Max Customers"
          type="number"
          value={appointment.maxCustomers}
          onChange={(e) => handleInputChange('maxCustomers', parseInt(e.target.value, 10))}
        />
        <FormControl fullWidth>
          <InputLabel>Storage Coordinator</InputLabel>
          <Select
            value={appointment.storageCoordinator}
            onChange={(e) => handleInputChange('storageCoordinator', e.target.value)}
            label="Storage Coordinator"
          >
            <MenuItem value="Coordinator A">Coordinator A</MenuItem>
            <MenuItem value="Coordinator B">Coordinator B</MenuItem>
            <MenuItem value="Coordinator C">Coordinator C</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            value={appointment.type}
            onChange={(e) => handleInputChange('type', e.target.value)}
            label="Type"
          >
            <MenuItem value="Move In">Move In</MenuItem>
            <MenuItem value="Move Out">Move Out</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={() => navigate(-1)} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained" disabled={saving}>
            {saving ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </Box>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AppointmentEditor;
