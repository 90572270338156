import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { Box, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconPickerItem } from 'react-icons-picker';
import { db } from '../firebase';

const NotificationsCarousel = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openLearnMore, setOpenLearnMore] = useState(false);

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const fetchNotifications = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      console.log('No user data found');
      return [];
    }

    const userData = userSnap.data();
    const shownNotifications = userData.shownNotifications || [];

    const notifications = [];
    for (const notifId of shownNotifications) {
      const notifRef = doc(db, 'notifications', notifId);
      const notifSnap = await getDoc(notifRef);
      if (notifSnap.exists()) {
        notifications.push({ id: notifSnap.id, ...notifSnap.data() });
      }
    }

    return notifications;
  };

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const notifs = await fetchNotifications(userId);
        setNotifications(notifs);
      } catch (err) {
        console.error('Error fetching notifications:', err);
      }
    };

    loadNotifications();
  }, [userId]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? notifications.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === notifications.length - 1 ? 0 : prevIndex + 1));
  };

  const currentNotification = notifications[currentIndex];
  if (!currentNotification) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '150px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="gray">
          No Notifications
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          p: 3,
          borderRadius: 2,
          border: `3px solid ${currentNotification.primaryColor || '#000'}`,
          backgroundColor: currentNotification.backgroundColor || '#f5f5f5',
          color: currentNotification.textColor || '#000',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Render Icon */}
        <IconPickerItem
          value={currentNotification.icon || 'FaUsers'}
          size={60}
          color={currentNotification.primaryColor || '#000'}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {currentNotification.title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {currentNotification.text}
        </Typography>

        {currentNotification.showLearnMore && (
          <Button variant="outlined" sx={{ mt: 2 }} onClick={() => setOpenLearnMore(true)}>
            Learn More
          </Button>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
          <IconButton onClick={handlePrevious}>
            <ArrowBack />
          </IconButton>
          <Typography variant="caption" sx={{ mx: 2 }}>
            {currentIndex + 1} of {notifications.length}
          </Typography>
          <IconButton onClick={handleNext}>
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>

      {/* Learn More Dialog */}
      <Dialog open={openLearnMore} onClose={() => setOpenLearnMore(false)}>
        <DialogTitle>{currentNotification.title}</DialogTitle>
        <DialogContent>
          <Typography>
            {currentNotification.learnMoreLink || 'No additional information available.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLearnMore(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationsCarousel;
