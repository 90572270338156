import React, { useState } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnalyticProvider from './contexts/AnalyticContext';
import Layout from './components/Layout';

/* Misc Pages */
import Dashboard from './pages/Dashboard';

/* Public Pages */
import Registration from './pages/Registration';
import Login from './pages/Login';
import StorableItems from './pages/StorableItems';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Pricing from './pages/Pricing';

/* Customer Pages */
import MyItems from './pages/customer/MyItems';
import Supplies from './pages/customer/Supplies';
import StoragePlanPrivate from './pages/customer/StoragePlanPrivate';
import SupportTicketForm from './pages/customer/SupportTicketForm';
import Settings from './pages/customer/AccountSettings';
import Appointments from './pages/customer/Appointments';
import AddItem from './pages/customer/AddItem';
import ItemInfo from './pages/customer/ItemInfo';
import Support from './pages/customer/Support';
import EditItem from './pages/customer/EditItem';
import Inbox from './pages/customer/Inbox';
import UserDashboard from './pages/customer/UserDashboard';
import Checkout from './pages/customer/Checkout';

/* Admin Pages */

import AppointmentList from './pages/admin/AppointmentList';
import UniversityEditor from './pages/admin/UniversityEditor';
import Universities from './pages/admin/UniversitySettings'; 
import AdminDashboard from './pages/admin/AdminDashboard';
import SystemMonitoring from './pages/admin/SystemMonitoring';
import SalesDashboard from './pages/admin/SalesDashboard';
import SalesDetail from './pages/admin/SalesDetail';
import UserDetail from './pages/admin/UserDetail';
import ContainerXrayPage from './pages/admin/ContainerXrayPage';
import UniversityContainersView from './pages/admin/UniversityContainersView';
import UniversityContainersSelection from './pages/admin/ContainersUniversityPicker';
import ContainerLocationEditor from './pages/admin/ContainerLocationEditor';
import ActivityFeed from './pages/admin/ActivityFeed';
import AppointmentEditor from './pages/admin/AppointmentEditor';
import NotificationDashboard from './pages/admin/NotificationDashboard';

/* Other imports */
import { NotificationProvider } from './contexts/NotificationContext'; // Import the NotificationProvider
import { useAuth } from './contexts/AuthContext';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';

import './App.css';
import NotificationEditor from './pages/admin/NotificationEditor';

const theme = createTheme({
  palette: {
    primary: {
      main: '#134F75',
    },
    secondary: {
      main: '#007bff',
      alternate: '#072a40',
    },
    background: {
      default: '#f8f9fa',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  function DashboardRoute() {

    const { userClaims } = useAuth();

    return (
      <PrivateRoute>
        <Layout
          theme={theme}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          breadcrumb={userClaims.role === 'admin' ? 'Admin Dashboard' : 'Customer Dashboard'}
        >
          <Routes>

            
            {userClaims.role === 'admin' ? (
              <>
                <Route path="" element={<AdminDashboard />} />
                <Route path="universities" element={<Universities />} />
                <Route path="universities/:universityID" element={<UniversityEditor />} />
                <Route path="system-monitoring" element={<SystemMonitoring />} />
                <Route path="notifications" element={<NotificationDashboard />} /> 
                <Route path="notifications/new" element={<NotificationEditor />} /> 
                <Route path="notifications/edit/:notificationId" element={<NotificationEditor />} /> 
                <Route path="containers" element={<UniversityContainersSelection />} />
                <Route path="containers/university/:universityID" element={<UniversityContainersView />} />
                <Route path="containers/university/:universityID/:containerLocationID" element={<ContainerLocationEditor />} />
                <Route path="containers/university/:universityID/:containerLocationID/appointments" element={<AppointmentList />} />
                <Route path="containers/university/:universityID/:containerLocationID/appointments/:appointmentID" element={<AppointmentEditor />} />
                <Route path="containers/container/:containerID" element={<ContainerXrayPage />} />
                <Route path="sales-dashboard" element={<SalesDashboard />} />
                <Route path="sales-dashboard/:transactionId" element={<SalesDetail />} />
                <Route path="users" element={<UserDashboard />} />
                <Route path="users/:userId" element={<UserDetail />} />
                <Route path="activity-feed" element={<ActivityFeed />} />
              </>
            ) : (
              
              <Route path="" element={<Dashboard />} />
            )}

            <Route path="items" element={<MyItems />} />
            <Route path="supplies" element={<Supplies />} />
            <Route path="plan" element={<StoragePlanPrivate />} />
            <Route path="add-item" element={<AddItem />} />
            <Route path="settings" element={<Settings />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="support" element={<Support />} />
            <Route path="support/create-ticket" element={<SupportTicketForm />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="container-viewer" element={<Dashboard />} />
            <Route path="edit-item/:itemId" element={<EditItem />} />
            <Route path="item-info/:itemId" element={<ItemInfo />} />
          </Routes>
        </Layout>
      </PrivateRoute>
    );
  }

  return (
    <Router>
      <AuthProvider>
        <AnalyticProvider>

          <NotificationProvider> {/* Wrap the app in NotificationProvider */}
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/storable-items" element={<StorableItems />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/dashboard/*" element={<DashboardRoute />} />
              </Routes>
            </ThemeProvider>
          </NotificationProvider>
        </AnalyticProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
