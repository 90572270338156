import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/system';

const slideIn = {
  animation: 'slideIn 4s ease-in-out forwards',
};

const keyframes = `
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
`;

const NotificationContainer = styled('div')({
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1000,
  opacity: 0, // Initial opacity set to 0
});

const getColor = (status) => {
  switch (status) {
    case 'success':
      return 'rgba(0, 128, 0, 0.5)'; // Semi-transparent green
    case 'error':
      return 'rgba(255, 0, 0, 0.5)'; // Semi-transparent red
    case 'info':
      return 'rgba(0, 0, 255, 0.5)'; // Semi-transparent blue
    case 'warning':
      return 'rgba(255, 165, 0, 0.5)'; // Semi-transparent orange
    default:
      return 'rgba(128, 128, 128, 0.5)'; // Semi-transparent gray
  }
};

const getIcon = (status) => {
  switch (status) {
    case 'success':
      return <CheckCircleIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'info':
      return <InfoIcon />;
    case 'warning':
      return <WarningIcon />;
    default:
      return <InfoIcon />;
  }
};

// Helper function to parse the message for link formatting
const parseMessage = (message) => {
  const linkRegex = /\[([^\]]+)\]\((\/[^\s]+|https?:\/\/[^\s]+)\)/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(message)) !== null) {
    const beforeLink = message.slice(lastIndex, match.index);
    if (beforeLink) {
      parts.push(beforeLink);
    }
    
    const isInternal = match[2].startsWith('/'); // Check if it's an internal link
    if (isInternal) {
      // Use <Link> for internal links
      parts.push(
        <Link key={match[2]} to={match[2]} style={{ color: 'blue' }}>
          {match[1]}
        </Link>
      );
    } else {
      // Use <a> for external links
      parts.push(
        <a key={match[2]} href={match[2]} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
          {match[1]}
        </a>
      );
    }

    lastIndex = linkRegex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push(message.slice(lastIndex));
  }

  return parts;
};

const Notification = ({ color, children }) => {
  const notificationStyle = {
    backgroundColor: getColor(color),
    color: 'black',
    padding: '10px 20px',
    margin: '10px 20px',
    borderRadius: '5px',
    border: '2px solid lightgray',
    display: 'flex',
    alignItems: 'center',
    ...slideIn,
  };

  return (
    <div style={notificationStyle}>
      {getIcon(color)}
      <span style={{ marginLeft: '10px' }}>{children}</span>
    </div>
  );
};

const NotificationComponent = ({ notifications, removeNotification }) => {
  useEffect(() => {
    const timers = notifications.map((notification) =>
      setTimeout(() => removeNotification(notification.id), 4000) // Updated timeout duration
    );
    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [notifications, removeNotification]);

  return (
    <NotificationContainer style={{ opacity: notifications.length > 0 ? 1 : 0 }}>
      <style>{keyframes}</style>
      {notifications.map((notification) => (
        <Notification key={notification.id} color={notification.status}>
          {parseMessage(notification.message)}
        </Notification>
      ))}
    </NotificationContainer>
  );
};

export default NotificationComponent;
