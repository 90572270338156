// File: src/components/NotificationEditorText.jsx

import React, { useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const NotificationEditorText = ({
    smsMessage,
    setSmsMessage,
}) => {

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
                SMS Notification
            </Typography>

            <TextField
                label="Message"
                value={smsMessage}
                onChange={(e) => setSmsMessage(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{ mb: 3 }}
            />

        </Box>
    );
};

NotificationEditorText.propTypes = {
    smsMessage: PropTypes.string.isRequired,
    setSmsMessage: PropTypes.func.isRequired,
};

export default NotificationEditorText;
