import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Paper,
  CircularProgress
} from '@mui/material';
import { LocalShipping, Logout } from '@mui/icons-material';

import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

import AppointmentTab from '../../components/AppointmentTab'; // <--- import your new component

const Appointments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState(null);

  // Move Out
  const [moveOutAppointmentDetails, setMoveOutAppointmentDetails] = useState(null);
  const [loadingMoveOutDetails, setLoadingMoveOutDetails] = useState(false);
  const [showMoveOutSelector, setShowMoveOutSelector] = useState(false);
  const [editingMoveOutLocation, setEditingMoveOutLocation] = useState(false);
  const [moveOutColleges, setMoveOutColleges] = useState([]);
  const [moveOutEntryways, setMoveOutEntryways] = useState([]);
  const [moveOutCollege, setMoveOutCollege] = useState('');
  const [moveOutEntryway, setMoveOutEntryway] = useState('');

  // Move In
  const [moveInAppointmentDetails, setMoveInAppointmentDetails] = useState(null);
  const [loadingMoveInDetails, setLoadingMoveInDetails] = useState(false);
  const [showMoveInSelector, setShowMoveInSelector] = useState(false);
  const [editingMoveInLocation, setEditingMoveInLocation] = useState(false);
  const [moveInColleges, setMoveInColleges] = useState([]);
  const [moveInEntryways, setMoveInEntryways] = useState([]);
  const [moveInCollege, setMoveInCollege] = useState('');
  const [moveInEntryway, setMoveInEntryway] = useState('');

  // Loading user
  const [loadingUserData, setLoadingUserData] = useState(true);

  const auth = getAuth();
  const user = auth.currentUser;

  // -----------------------------
  // 1. Fetch user doc on mount
  // -----------------------------
  useEffect(() => {
    if (!user) return;
    const fetchUserDoc = async () => {
      setLoadingUserData(true);
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userDocRef);
        if (userSnap.exists()) {
          const data = userSnap.data();
          setUserData(data);

          if (data.moveOutCollege) setMoveOutCollege(data.moveOutCollege);
          if (data.moveOutEntryway) setMoveOutEntryway(data.moveOutEntryway);

          if (data.moveInCollege) setMoveInCollege(data.moveInCollege);
          if (data.moveInEntryway) setMoveInEntryway(data.moveInEntryway);
        }
      } catch (error) {
        console.error('Error fetching user document:', error);
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchUserDoc();
  }, [user]);

  // --------------------------------
  // 2. Fetch Move Out colleges
  // --------------------------------
  useEffect(() => {
    if (!userData?.moveOutUniversityID) return;
    const fetchMoveOutColleges = async () => {
      try {
        setLoadingMoveOutDetails(true);
        const collegesCollection = collection(
          db,
          `universities/${userData.moveOutUniversityID}/containerLocations`
        );
        const snapshot = await getDocs(collegesCollection);
        const collegeList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMoveOutColleges(collegeList);
      } catch (error) {
        console.error("Error fetching Move Out colleges:", error);
      } finally {
        setLoadingMoveOutDetails(false);
      }
    };
    fetchMoveOutColleges();
  }, [userData?.moveOutUniversityID]);

  // --------------------------------
  // 3. Fetch Move In colleges
  // --------------------------------
  useEffect(() => {
    if (!userData?.moveInUniversityID) return;
    const fetchMoveInColleges = async () => {
      try {
        setLoadingMoveInDetails(true);
        const collegesCollection = collection(
          db,
          `universities/${userData.moveInUniversityID}/containerLocations`
        );
        const snapshot = await getDocs(collegesCollection);
        const collegeList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMoveInColleges(collegeList);
      } catch (error) {
        console.error("Error fetching Move In colleges:", error);
      } finally {
        setLoadingMoveInDetails(false);
      }
    };
    fetchMoveInColleges();
  }, [userData?.moveInUniversityID]);

  // --------------------------------
  // 4. Move Out appointment details
  // --------------------------------
  useEffect(() => {
    if (!userData?.moveOutAppointment
      || !userData?.moveOutUniversityID
      || !userData?.moveOutContainerLocationID) {
      setMoveOutAppointmentDetails(null);
      return;
    }

    const fetchMoveOutDetails = async () => {
      setLoadingMoveOutDetails(true);
      try {
        const { moveOutUniversityID, moveOutContainerLocationID, moveOutAppointment } = userData;
        const apptDocRef = doc(
          db,
          'universities',
          moveOutUniversityID,
          'containerLocations',
          moveOutContainerLocationID,
          'appointments',
          moveOutAppointment
        );
        const apptSnap = await getDoc(apptDocRef);
        if (apptSnap.exists()) {
          setMoveOutAppointmentDetails(apptSnap.data());
        }
      } catch (error) {
        console.error('Error fetching Move Out appointment:', error);
      } finally {
        setLoadingMoveOutDetails(false);
      }
    };

    fetchMoveOutDetails();
  }, [userData]);

  // --------------------------------
  // 5. Move In appointment details
  // --------------------------------
  useEffect(() => {
    if (!userData?.moveInAppointment
      || !userData?.moveInUniversityID
      || !userData?.moveInContainerLocationID) {
      setMoveInAppointmentDetails(null);
      return;
    }

    const fetchMoveInDetails = async () => {
      setLoadingMoveInDetails(true);
      try {
        const { moveInUniversityID, moveInContainerLocationID, moveInAppointment } = userData;
        const apptDocRef = doc(
          db,
          'universities',
          moveInUniversityID,
          'containerLocations',
          moveInContainerLocationID,
          'appointments',
          moveInAppointment
        );
        const apptSnap = await getDoc(apptDocRef);
        if (apptSnap.exists()) {
          setMoveInAppointmentDetails(apptSnap.data());
        }
      } catch (error) {
        console.error('Error fetching Move In appointment:', error);
      } finally {
        setLoadingMoveInDetails(false);
      }
    };

    fetchMoveInDetails();
  }, [userData]);

  // Handle tab switch
  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  // Appointment selection
  const handleSelectMoveOut = async (selectedAppointment) => {
    if (!user) return;
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        moveOutAppointment: selectedAppointment.id,
      });
      setUserData(prev => ({ ...prev, moveOutAppointment: selectedAppointment.id }));
      setShowMoveOutSelector(false);
    } catch (error) {
      console.error('Error updating Move Out appointment:', error);
    }
  };

  const handleSelectMoveIn = async (selectedAppointment) => {
    if (!user) return;
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        moveInAppointment: selectedAppointment.id,
      });
      setUserData(prev => ({ ...prev, moveInAppointment: selectedAppointment.id }));
      setShowMoveInSelector(false);
    } catch (error) {
      console.error('Error updating Move In appointment:', error);
    }
  };

  // Move Out location editing
  const handleEditMoveOutLocation = () => setEditingMoveOutLocation(true);
  const handleCancelMoveOutLocation = () => {
    setEditingMoveOutLocation(false);
    // (Optional) reset local state from userData if discarding changes
    // setMoveOutCollege(userData.moveOutCollege || '');
    // setMoveOutEntryway(userData.moveOutEntryway || '');
  };
  const handleMoveOutCollegeChange = (e) => {
    const newCollege = e.target.value;
    setMoveOutCollege(newCollege);
    setMoveOutEntryway('');
    const collegeDoc = moveOutColleges.find(c => c.name === newCollege);
    setMoveOutEntryways(collegeDoc?.servicedEntryways || []);
  };
  const handleMoveOutEntrywayChange = (e) => {
    setMoveOutEntryway(e.target.value);
  };
  const handleSaveMoveOutLocation = async () => {
    if (!user) return;
    try {
      const chosenCollegeDoc = moveOutColleges.find(c => c.name === moveOutCollege);
      if (!chosenCollegeDoc) return;
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        moveOutContainerLocationID: chosenCollegeDoc.id,
        moveOutCollege,
        moveOutEntryway
      });
      setUserData(prev => ({
        ...prev,
        moveOutContainerLocationID: chosenCollegeDoc.id,
        moveOutCollege,
        moveOutEntryway
      }));
      setEditingMoveOutLocation(false);
    } catch (error) {
      console.error('Error updating Move Out location:', error);
    }
  };

  // Move In location editing
  const handleEditMoveInLocation = () => setEditingMoveInLocation(true);
  const handleCancelMoveInLocation = () => {
    setEditingMoveInLocation(false);
    // (Optional) reset from userData if discarding changes
    // setMoveInCollege(userData.moveInCollege || '');
    // setMoveInEntryway(userData.moveInEntryway || '');
  };
  const handleMoveInCollegeChange = (e) => {
    const newCollege = e.target.value;
    setMoveInCollege(newCollege);
    setMoveInEntryway('');
    const collegeDoc = moveInColleges.find(c => c.name === newCollege);
    setMoveInEntryways(collegeDoc?.servicedEntryways || []);
  };
  const handleMoveInEntrywayChange = (e) => {
    setMoveInEntryway(e.target.value);
  };
  const handleSaveMoveInLocation = async () => {
    if (!user) return;
    try {
      const chosenCollegeDoc = moveInColleges.find(c => c.name === moveInCollege);
      if (!chosenCollegeDoc) return;
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        moveInContainerLocationID: chosenCollegeDoc.id,
        moveInCollege,
        moveInEntryway
      });
      setUserData(prev => ({
        ...prev,
        moveInContainerLocationID: chosenCollegeDoc.id,
        moveInCollege,
        moveInEntryway
      }));
      setEditingMoveInLocation(false);
    } catch (error) {
      console.error('Error updating Move In location:', error);
    }
  };

  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">Please log in to view appointments.</Typography>
      </Box>
    );
  }

  if (loadingUserData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Appointments
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manage your scheduled appointments below. Each tab shows your Move Out or Move In details.
      </Typography>

      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab icon={<Logout />} label="Move Out Appointment" />
          <Tab icon={<LocalShipping />} label="Move In Appointment" />
        </Tabs>
      </Paper>

      {/* ================= MOVE OUT TAB ================= */}
      {tabIndex === 0 && (
        <Box sx={{ mt: 3 }}>
          <AppointmentTab
            tabTitle="Move Out Appointment"
            instructionsTitle="Move Out Instructions"
            coordinatorName="Johnee Doe" // Or dynamic coordinator info if you have it
            loadingDetails={loadingMoveOutDetails}
            appointmentDetails={moveOutAppointmentDetails}
            onShowSelector={() => setShowMoveOutSelector(true)}
            showSelector={showMoveOutSelector}
            onHideSelector={() => setShowMoveOutSelector(false)}
            onSelectAppointment={handleSelectMoveOut}
            universityID={userData?.moveOutUniversityID}
            containerLocationID={userData?.moveOutContainerLocationID}

            // Location editing props
            locationCollege={moveOutCollege}
            colleges={moveOutColleges}
            editingLocation={editingMoveOutLocation}
            onEditLocation={handleEditMoveOutLocation}
            onCancelLocation={handleCancelMoveOutLocation}
            onSaveLocation={handleSaveMoveOutLocation}
            onCollegeChange={handleMoveOutCollegeChange}
          />
        </Box>
      )}

      {/* ================= MOVE IN TAB ================= */}
      {tabIndex === 1 && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <AppointmentTab
            tabTitle="Move In Appointment"
            instructionsTitle="Move In Instructions"
            coordinatorName="Jane Smith" // Or dynamic
            loadingDetails={loadingMoveInDetails}
            appointmentDetails={moveInAppointmentDetails}
            onShowSelector={() => setShowMoveInSelector(true)}
            showSelector={showMoveInSelector}
            onHideSelector={() => setShowMoveInSelector(false)}
            onSelectAppointment={handleSelectMoveIn}
            universityID={userData?.moveInUniversityID}
            containerLocationID={userData?.moveInContainerLocationID}

            // Location editing
            locationCollege={moveInCollege}
            locationEntryway={moveInEntryway}
            colleges={moveInColleges}
            editingLocation={editingMoveInLocation}
            onEditLocation={handleEditMoveInLocation}
            onCancelLocation={handleCancelMoveInLocation}
            onSaveLocation={handleSaveMoveInLocation}
            onCollegeChange={handleMoveInCollegeChange}
            onEntrywayChange={handleMoveInEntrywayChange}
          />
        </Paper>
      )}
    </Box>
  );
};

export default Appointments;
