import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  CircularProgress,
  Grid,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip,
  Paper,
} from '@mui/material';
import {
  Visibility as PageViewIcon,
  TimerOff as TimedOutIcon,
  TouchApp as ButtonClickIcon,
  ExitToApp as LogoutIcon,
  Login as LoginIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { getFirestore, collection, query, orderBy, limit, onSnapshot, doc, getDoc, where } from 'firebase/firestore';

const ActivityFeed = () => {
  const [activities, setActivities] = useState([]);
  const [activityLimit, setActivityLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [activeUsers, setActiveUsers] = useState([]);
  const [recentlyActiveUsers, setRecentlyActiveUsers] = useState([]);
  const [hiddenUsers, setHiddenUsers] = useState(new Set());
  const [expandedActivity, setExpandedActivity] = useState(null); // State for expanded activity

  const db = getFirestore();
  const navigate = useNavigate();

  const generateColor = (id) => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = '#' + ((hash >> 24) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 16) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 8) & 0xFF).toString(16).padStart(2, '0');
    return color;
  };

  const toggleUserVisibility = (userId) => {
    setHiddenUsers((prevHiddenUsers) => {
      const updatedHiddenUsers = new Set(prevHiddenUsers);
      if (updatedHiddenUsers.has(userId)) {
        updatedHiddenUsers.delete(userId);
      } else {
        updatedHiddenUsers.add(userId);
      }
      return updatedHiddenUsers;
    });
  };

  const toggleActivityExpansion = (activityId) => {
    setExpandedActivity((prevExpandedActivity) =>
      prevExpandedActivity === activityId ? null : activityId
    );
  };

  const getUserNameById = (userId) => {
    // Search in activeUsers
    let user = activeUsers.find(user => user.id === userId);
    if (user) return `${user.firstName} ${user.lastName}`;
    
    // Search in recentlyActiveUsers if not found in activeUsers
    user = recentlyActiveUsers.find(user => user.id === userId);
    if (user) return `${user.firstName} ${user.lastName}`;
    
    // Return userId if user not found in either list
    return userId;
  };

  const handleUserClick = (userId) => {
    navigate(`/dashboard/users/${userId}`);
  };

  useEffect(() => {
    const fetchFeedActivities = async () => {
      try {
        const feedCollection = collection(db, 'analytics/feed/data');
        const feedQuery = query(feedCollection, orderBy('timestamp', 'desc'), limit(activityLimit));

        const unsubscribe = onSnapshot(feedQuery, (snapshot) => {
          const activityList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActivities(activityList);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching activities: ', error);
        setLoading(false);
      }
    };

    fetchFeedActivities();
  }, [db, activityLimit]);

  useEffect(() => {
    const fetchActiveUsers = () => {
      const sessionsCollection = collection(db, 'sessions');
      const activeSessionsQuery = query(sessionsCollection, where('status', '==', 'active'));
  
      const unsubscribe = onSnapshot(activeSessionsQuery, async (snapshot) => {
        const activeUsersList = await Promise.all(
          snapshot.docs.map(async (activeUserDoc) => {
            const sessionData = activeUserDoc.data();
            const userId = sessionData.userId || activeUserDoc.id;
  
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
  
            if (userDoc.exists()) {
              const userData = userDoc.data();
              return {
                id: userId,
                firstName: userData?.firstName || 'Unknown',
                lastName: userData?.lastName || 'User',
                email: userData?.email || 'No email',
                sessionId: sessionData.sessionID,
              };
            } else {
              return {
                id: userId,
                firstName: 'Unknown',
                lastName: 'User',
                email: 'No email',
                sessionId: sessionData.sessionID,
              };
            }
          })
        );
  
        setActiveUsers(activeUsersList);
      });
  
      return () => unsubscribe();
    };
  
    fetchActiveUsers();
  }, [db]);
  
  useEffect(() => {
    if (activities.length > 0) {
      const activeUserIds = new Set(activeUsers.map(user => user.id));
      const processedUserIds = new Set();
  
      const fetchRecentlyActiveUsers = async () => {
        const recentlyActiveList = await Promise.all(
          activities
            .filter(activity => {
              if (!activeUserIds.has(activity.userId) && !processedUserIds.has(activity.userId)) {
                processedUserIds.add(activity.userId);
                return true;
              }
              return false;
            })
            .map(async (activity) => {
              const userDocRef = doc(db, 'users', activity.userId);
              const userDoc = await getDoc(userDocRef);
              const userData = userDoc.data();
              return {
                id: activity.userId,
                firstName: userData?.firstName || 'Unknown',
                lastName: userData?.lastName || 'User',
                email: userData?.email || 'No email',
                timestamp: activity.timestamp,
              };
            })
        );
  
        setRecentlyActiveUsers(recentlyActiveList);
      };
  
      fetchRecentlyActiveUsers();
    }
  }, [activities, activeUsers, db]);
  
  const handleLimitChange = (e) => {
    setActivityLimit(parseInt(e.target.value, 10));
  };

  const getActivityIcon = (activityType) => {
    switch (activityType) {
      case 'page_view':
        return <PageViewIcon />;
      case 'timed_out':
        return <TimedOutIcon />;
      case 'button_click':
        return <ButtonClickIcon />;
      case 'login':
        return <LoginIcon />;
      case 'logout':
        return <LogoutIcon />;
      default:
        return <Avatar>{activityType.charAt(0).toUpperCase()}</Avatar>;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Box sx={{display: "flex", justifyContent:"space-between"}}>
            <Typography variant="h5" gutterBottom>
              Recent Activity
            </Typography>

            <FormControl variant="outlined" sx={{ mb: 2, minWidth: 120 }}>
              <InputLabel id="activity-limit-label">Show up to</InputLabel>
              <Select
                labelId="activity-limit-label"
                id="activity-limit"
                value={activityLimit}
                onChange={handleLimitChange}
                label="Show up to"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress />
            </Box>
          ) : activities.length > 0 ? (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {activities
                .filter(activity => !hiddenUsers.has(activity.userId))
                .map((activity) => (
                <React.Fragment key={activity.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: generateColor(activity.userId) }}>
                        {getActivityIcon(activity.activityType)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={6}>
                            {activity.description}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            User: {getUserNameById(activity.userId)}
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={6}>
                            {new Date(activity.timestamp).toLocaleString()}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            Session ID: {activity.sessionId || 'N/A'}
                          </Grid>
                        </Grid>
                      }
                    />
                    <IconButton onClick={() => toggleActivityExpansion(activity.id)}>
                      {expandedActivity === activity.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={expandedActivity === activity.id} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} sx={{ my: 2 }}>
                      <Table size="small">
                        <TableBody>
                          {Object.entries(activity).map(([key, value], index) => (
                            <TableRow key={key} sx={{ backgroundColor: index % 2 === 0 ? 'gray.50' : 'white' }}>
                              <TableCell component="th" scope="row">
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  {key}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">{value}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography>No recent activities found.</Typography>
          )}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            Users
          </Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>Currently Active</Typography>
          {activeUsers.length > 0 ? (
            <List>
              {activeUsers.map((user) => (
                <React.Fragment key={user.id}>
                  <Box onClick={() => handleUserClick(user.id)} sx={{ cursor: 'pointer' }}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: generateColor(user.id) }}>
                          {user.firstName.charAt(0).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={user.email}
                      />
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        toggleUserVisibility(user.id);
                      }}>
                        {hiddenUsers.has(user.id) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </ListItem>
                  </Box>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <ListItem>
              <ListItemText primary={`No active users found.`} sx={{ color: "text.secondary" }} />
            </ListItem>
          )}

          <Typography variant="h6" sx={{ mt: 2 }}>Recently Active</Typography>
          {recentlyActiveUsers.length > 0 ? (
            <List>
              {recentlyActiveUsers.map((user) => (
                <React.Fragment key={user.id}>
                  <Box >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: generateColor(user.id) }}>
                          {user.firstName ? user.firstName.charAt(0).toUpperCase() : "?"}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={user.email}
                      />
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        toggleUserVisibility(user.id);
                      }}>

                      <Tooltip title={hiddenUsers.has(user.id) ? "Show Activity" : "Hide Activity"}>
                        {hiddenUsers.has(user.id) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                       </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => handleUserClick(user.id)}>
                      <Tooltip title="User Details">
                       < MoreVertIcon />
                       </Tooltip>
                      </IconButton>
                    </ListItem>
                  </Box>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography>No recently active users found.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ActivityFeed;
