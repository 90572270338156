// NotificationComponentsPopup.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaveAs } from '@mui/icons-material';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const NotificationComponentsPopup = ({
    open,
    onClose,
    currentBody,
    onComponentInsert,   // Callback to insert a placeholder e.g. "{{footer}}"
    onComponentEdit,     // Callback to replace the editor content with a component's HTML
    onComponentsChange   // Callback to update parent's mapping of components
}) => {
    const [components, setComponents] = useState([]);
    const [newComponentName, setNewComponentName] = useState('');
    const [loadingComponents, setLoadingComponents] = useState(false);

    // Confirmation state for editing a component (i.e. replacing editor content)
    const [componentToEdit, setComponentToEdit] = useState(null);
    const [confirmEditOpen, setConfirmEditOpen] = useState(false);

    // Confirmation state for deleting a component
    const [componentToDelete, setComponentToDelete] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const fetchComponents = async () => {
        setLoadingComponents(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'emailComponents'));
            const componentsData = [];
            querySnapshot.forEach((docSnap) => {
                componentsData.push({ id: docSnap.id, ...docSnap.data() });
            });
            setComponents(componentsData);
        } catch (err) {
            console.error('Error fetching components: ', err);
        }
        setLoadingComponents(false);
    };

    useEffect(() => {
        if (open) {
            fetchComponents();
        }
    }, [open]);

    // Update parent with the new mapping whenever components change.
    useEffect(() => {
        if (onComponentsChange) {
            const mapping = {};
            components.forEach((comp) => {
                mapping[comp.name.trim()] = comp.body;
            });
            onComponentsChange(mapping);
        }
    }, [components, onComponentsChange]);

    const handleSaveUpdatedComponent = async (component) => {
        if (!component) return;
        try {
            const updatedComponent = {
                ...component,
                body: currentBody, // Overwrite the body with currentBody
            };
            await updateDoc(doc(db, 'emailComponents', component.id), updatedComponent);
            fetchComponents(); // Refresh the components list
        } catch (err) {
            console.error('Error saving updated component: ', err);
        }
    };


    const handleSaveAsComponent = async () => {
        if (!newComponentName) return;
        try {
            const payload = {
                name: newComponentName,
                body: currentBody // Save current HTML as component content
            };
            await addDoc(collection(db, 'emailComponents'), payload);
            setNewComponentName('');
            fetchComponents();
        } catch (err) {
            console.error('Error saving component: ', err);
        }
    };

    // Opens the delete confirmation dialog.
    const handleRequestDeleteComponent = (component) => {
        setComponentToDelete(component);
        setConfirmDeleteOpen(true);
    };

    const confirmDeleteComponent = async () => {
        if (componentToDelete) {
            try {
                await deleteDoc(doc(db, 'emailComponents', componentToDelete.id));
                fetchComponents();
            } catch (err) {
                console.error('Error deleting component: ', err);
            }
        }
        setConfirmDeleteOpen(false);
        setComponentToDelete(null);
    };

    // When clicking the edit icon, show a confirmation to replace the notification editor content.
    const handleEditComponent = (component) => {
        setComponentToEdit(component);
        setConfirmEditOpen(true);
    };

    const confirmEditComponent = () => {
        if (componentToEdit && onComponentEdit) {
            // Replace the editor content with the component's HTML.
            onComponentEdit(componentToEdit.body);
        }
        setConfirmEditOpen(false);
        setComponentToEdit(null);
        onClose(); // Optionally close the popup.
    };

    // Copies the placeholder text (e.g., "{{footer}}") to the clipboard.
    const handleCopyPlaceholder = (component) => {
        const placeholder = `{{${component.name.trim()}}}`;
        navigator.clipboard.writeText(placeholder)
            .catch((err) => {
                console.error('Failed to copy placeholder: ', err);
            });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Email Components</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Create new component</Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                            <TextField
                                label="Component Name"
                                value={newComponentName}
                                onChange={(e) => setNewComponentName(e.target.value)}
                                fullWidth
                            />
                            <Tooltip title="Save the current email body as a new component">
                                <Button variant="contained" onClick={handleSaveAsComponent}>
                                    Save
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Available Components
                    </Typography>
                    {loadingComponents ? (
                        <Typography>Loading components...</Typography>
                    ) : (
                        <List>
                            {components.map((component) => (
                                <ListItem key={component.id} divider>
                                    <ListItem sx={{display: "flex", flexDirection: "row"}}>
                                        <Tooltip title="Copy placeholder">
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleCopyPlaceholder(component)}
                                                sx={{ mr: 1, width: "auto"}}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <ListItemText
                                            primary={component.name}
                                            secondary={`Usage form: {{${component.name.trim()}}}`}
                                        />
                                    </ListItem>
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Edit component">
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleEditComponent(component)}
                                                sx={{ mr: 1 }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Save component as current email body">
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleSaveUpdatedComponent(component)}
                                                sx={{ mr: 1 }}
                                            >
                                                <SaveAs />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete component">
                                            <IconButton
                                                edge="end"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRequestDeleteComponent(component);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Tooltip title="Close this dialog">
                        <Button onClick={onClose}>Close</Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Editing a Component */}
            <Dialog open={confirmEditOpen} onClose={() => setConfirmEditOpen(false)}>
                <DialogTitle>Replace Editor Content?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to replace the current email content with the HTML for component&nbsp;
                        <strong>{componentToEdit?.name}</strong>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Tooltip title="Cancel edit">
                        <Button onClick={() => setConfirmEditOpen(false)}>Cancel</Button>
                    </Tooltip>
                    <Tooltip title="Replace content">
                        <Button variant="contained" onClick={confirmEditComponent}>
                            Yes, Replace Content
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Deleting a Component */}
            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                <DialogTitle>Delete Component?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete the component&nbsp;
                        <strong>{componentToDelete?.name}</strong>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Tooltip title="Cancel deletion">
                        <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                    </Tooltip>
                    <Tooltip title="Confirm deletion">
                        <Button variant="contained" color="error" onClick={confirmDeleteComponent}>
                            Delete
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NotificationComponentsPopup;
