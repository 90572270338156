import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Tooltip, Menu, Checkbox, ListItemText, Divider,
  InputAdornment, TablePagination, CircularProgress, useTheme
} from '@mui/material';
import { Add, Edit, FilterAltOutlined, FilterAlt, GetApp, ArrowDropDown, ArrowDropUp, Search, ClearAll } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';

const UsersDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [filterAnchors, setFilterAnchors] = useState({});
  const [filters, setFilters] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const registrationDate = data.registrationDate ? new Date(data.registrationDate.seconds * 1000).toLocaleDateString() : 'N/A';
          return { id: doc.id, ...data, registrationDate };
        });
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleClickOpen = () => {
    setEditingUser(null);
    setOpen(true);
  };

  const handleEditClick = (user) => {
    navigate(`/dashboard/users/${user.id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingUser(null);
  };

  const handleSave = () => {
    // Logic to save the user
    setOpen(false);
    setEditingUser(null);
  };

  const handleExport = () => {
    // Logic to export user data
  };


  const handleFilterClick = (event, column) => {
    setFilterAnchors({ ...filterAnchors, [column]: event.currentTarget });
  };

  const handleFilterClose = (column) => {
    setFilterAnchors({ ...filterAnchors, [column]: null });
  };

  const handleFilterChange = (column, value) => {
    const columnFilters = filters[column] || [];
    const newFilters = columnFilters.includes(value)
      ? columnFilters.filter((v) => v !== value)
      : [...columnFilters, value];
    setFilters({ ...filters, [column]: newFilters });
  };

  const handleFilterSearchChange = (event) => {
    setFilterSearch(event.target.value);
  };

  const handleSelectAll = (column) => {
    const columnValues = users.map((row) => row[column])
      .filter((value, index, self) => self.indexOf(value) === index);
    setFilters({ ...filters, [column]: columnValues });
  };

  const handleDeselectAll = (column) => {
    setFilters({ ...filters, [column]: [] });
  };

  const handleSort = (column) => {
    const isAsc = sortOrder[column] === 'asc';
    setSortOrder({ [column]: isAsc ? 'desc' : 'asc' });
    const sortedUsers = [...users].sort((a, b) => {
      if (a[column] < b[column]) return isAsc ? -1 : 1;
      if (a[column] > b[column]) return isAsc ? 1 : -1;
      return 0;
    });
    setUsers(sortedUsers);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = (data) => {
    return data.filter((row) =>
      Object.keys(filters).every((column) =>
        filters[column]?.length ? filters[column].includes(row[column]) : true
      )
    );
  };

  const filteredUsers = applyFilters(users);

  const clearFilters = () => {
    setFilters({});
    setSortOrder({});
    setFilterSearch('');
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Users Dashboard
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Registered Users</Typography>
        <Box>
          <Tooltip title="Export User Data">
            <IconButton onClick={handleExport}>
              <GetApp />
            </IconButton>
          </Tooltip>
          {Object.keys(filters).some(column => filters[column]?.length) && (
            <Button
              variant="outlined"
              startIcon={<ClearAll />}
              onClick={clearFilters}
              sx={{ mr: 2 }}
            >
              Clear Filters
            </Button>
          )}
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleClickOpen}>
            Create New User
          </Button>
        </Box>
      </Box>
      <Paper sx={{ p: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {['firstName', 'lastName', 'university', 'role', 'email', 'registrationDate', 'status', 'uid'].map((column) => (
                    <TableCell key={column} sx={{pr: 0, pl: 2}}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight="bold" fontSize="0.9em" >{column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Typography>
                        <IconButton
                          size="small"
                          onClick={(e) => handleFilterClick(e, column)}
                          sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                        >
                          {filters[column]?.length ? <FilterAlt /> : <FilterAltOutlined sx={{ strokeWidth: 2 }} />}
                        </IconButton>
                        <Menu
                          anchorEl={filterAnchors[column]}
                          open={Boolean(filterAnchors[column])}
                          onClose={() => handleFilterClose(column)}
                        >
                          <Box sx={{ p: 1 }}>
                            <TextField
                              variant="outlined"
                              placeholder="Search..."
                              fullWidth
                              value={filterSearch}
                              onChange={handleFilterSearchChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                              <Button onClick={() => handleSelectAll(column)}>Select All</Button>
                              <Button onClick={() => handleDeselectAll(column)}>Deselect All</Button>
                            </Box>
                            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                              {users.map((row) => {
                                const value = row[column];
                                const displayValue = (value || 'N/A').toString();
                                return displayValue;
                              })
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .filter((value) => value.toLowerCase().includes(filterSearch.toLowerCase()))
                                .map((value) => (
                                  <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                    <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                    <ListItemText primary={value} />
                                  </MenuItem>
                                ))}
                            </Box>
                            <Divider />
                            <Button onClick={() => handleSort(column)}>
                              {sortOrder[column] === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
                              Sort
                            </Button>
                          </Box>
                        </Menu>
                      </Box>
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                  <TableRow key={user.id} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}>
                    <TableCell sx={{py: 0}}>{user.firstName || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.lastName || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.university || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.role || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.email || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.registrationDate || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.status || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>{user.uid || 'N/A'}</TableCell>
                    <TableCell sx={{py: 0}}>
                      <IconButton onClick={() => handleEditClick(user)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingUser ? 'Edit User' : 'Create New User'}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="First Name" fullWidth defaultValue={editingUser?.firstName || ''} />
          <TextField margin="dense" label="Last Name" fullWidth defaultValue={editingUser?.lastName || ''} />
          <TextField margin="dense" label="University" fullWidth defaultValue={editingUser?.university || ''} />
          <TextField margin="dense" label="Role" fullWidth defaultValue={editingUser?.role || ''} />
          <TextField margin="dense" label="Email" fullWidth defaultValue={editingUser?.email || ''} />
          <TextField margin="dense" label="Registration Date" type="date" fullWidth defaultValue={editingUser?.registrationDate || ''} />
          <TextField margin="dense" label="Status" fullWidth defaultValue={editingUser?.status || ''} />
          <TextField margin="dense" label="Registration Phase" fullWidth defaultValue={editingUser?.registrationPhase || ''} />
          <TextField margin="dense" label="UID" fullWidth defaultValue={editingUser?.uid || ''} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {editingUser ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UsersDashboard;
