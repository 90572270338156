// File: src/components/NotificationEditorScheduler.jsx

import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    TextField,
    FormControlLabel,
    RadioGroup,
    Button,
    Radio
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';

const NotificationEditorScheduler = ({
    schedules,
    setSchedules,
    availableTriggers = [], // Default to empty array to prevent undefined errors
}) => {
    // Define offset options
    const offsetUnits = ['seconds', 'minutes', 'hours', 'days', 'weeks'];
    const offsetDirections = ['before', 'after'];

    // Handler to add a new schedule
    const handleAddSchedule = () => {
        setSchedules((prev) => [
            ...prev,
            {
                trigger: '',
                customEventName: '',
                immediate: true,
                offsetValue: 0,
                offsetDirection: 'after',
                offsetUnit: 'minutes',
            },
        ]);
    };

    // Handler to update a specific schedule's field
    const handleScheduleChange = (index, field, value) => {
        const updatedSchedules = [...schedules];
        updatedSchedules[index][field] = value;
        setSchedules(updatedSchedules);
    };

    // Handler to remove a schedule
    const handleRemoveSchedule = (index) => {
        setSchedules((prev) => prev.filter((_, i) => i !== index));
    };

    // Extract trigger names safely
    const triggerOptions = Array.isArray(availableTriggers) ? availableTriggers.map((t) => t.name) : [];

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Notification Scheduler
            </Typography>

            {schedules.map((schedule, index) => {
                // Find the trigger object to determine if 'before' is supported
                const triggerObj = availableTriggers.find(
                    (t) => t.name === schedule.trigger
                ) || { supportsBefore: true }; // Default to true if not found

                return (
                    <Box
                        key={index}
                        sx={{
                            mb: 3,
                            p: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                {/* Trigger Selection */}
                                <FormControl sx={{ minWidth: 140 }}>
                                    <InputLabel>Trigger</InputLabel>
                                    <Select
                                        value={schedule.trigger || ''}
                                        label="Trigger"
                                        onChange={(e) =>
                                            handleScheduleChange(index, 'trigger', e.target.value)
                                        }
                                    >
                                        {triggerOptions.map((t) => (
                                            <MenuItem key={t} value={t}>
                                                {t}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="custom">Custom</MenuItem>
                                    </Select>
                                </FormControl>

                                {/* Custom Event Name */}
                                {schedule.trigger === 'custom' && (
                                    <TextField
                                        label="Custom Event Name"
                                        value={schedule.customEventName}
                                        onChange={(e) =>
                                            handleScheduleChange(index, 'customEventName', e.target.value)
                                        }
                                        sx={{ ml: 2, mt: 1 }}
                                    />
                                )}
                            </Box>

                            {/* Immediate or Time-based */}
                            <RadioGroup
                                row
                                value={schedule.immediate ? 'immediate' : 'time-based'}
                                onChange={(e) => {
                                    const isImmediate = e.target.value === 'immediate';
                                    handleScheduleChange(index, 'immediate', isImmediate);
                                }}
                            >
                                <FormControlLabel
                                    value="immediate"
                                    control={<Radio />}
                                    label="Send Immediately"
                                />
                                <FormControlLabel
                                    value="time-based"
                                    control={<Radio />}
                                    label="Schedule a Specific Time"
                                />
                            </RadioGroup>

                            {/* Delete Schedule Button */}
                            <IconButton
                                color="error"
                                onClick={() => handleRemoveSchedule(index)}
                                sx={{ alignSelf: 'center' }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>

                        {/* Offset Configuration for Time-based Schedules */}
                        {!schedule.immediate && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
                                {/* Offset Value */}
                                <TextField
                                    label="Number"
                                    type="number"
                                    sx={{ width: 100 }}
                                    value={schedule.offsetValue}
                                    onChange={(e) =>
                                        handleScheduleChange(index, 'offsetValue', e.target.value)
                                    }
                                />

                                {/* Before/After */}
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel>Before/After</InputLabel>
                                    <Select
                                        value={schedule.offsetDirection}
                                        label="Before/After"
                                        onChange={(e) =>
                                            handleScheduleChange(index, 'offsetDirection', e.target.value)
                                        }
                                    >
                                        {offsetDirections.map((dir) => (
                                            <MenuItem
                                                key={dir}
                                                value={dir}
                                                disabled={dir === 'before' && !triggerObj.supportsBefore}
                                            >
                                                {dir}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Time Units */}
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel>Unit</InputLabel>
                                    <Select
                                        value={schedule.offsetUnit}
                                        label="Unit"
                                        onChange={(e) =>
                                            handleScheduleChange(index, 'offsetUnit', e.target.value)
                                        }
                                    >
                                        {offsetUnits.map((unit) => (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </Box>
                );
            })}

            {/* Add Schedule Button */}
            <Button variant="contained" onClick={handleAddSchedule}>
                Add Schedule
            </Button>
        </Box>
    );

};

NotificationEditorScheduler.propTypes = {
    schedules: PropTypes.array.isRequired,
    setSchedules: PropTypes.func.isRequired,
    availableTriggers: PropTypes.array.isRequired,
};

export default NotificationEditorScheduler;
