// File: src/components/NotificationEditorDetails.jsx

import React from 'react';
import {
    Box,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconPicker from 'react-icons-picker'; // Ensure this is installed and configured

const NotificationEditorDetails = ({
    detailName,
    setDetailName,
    detailDescription,
    setDetailDescription,
    detailIcon,
    setDetailIcon,
    detailHasWeb,
    setDetailHasWeb,
    detailHasSms,
    setDetailHasSms,
    detailHasEmail,
    setDetailHasEmail,
}) => {

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Details
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Detail Name */}
                <TextField
                    label="Name"
                    value={detailName}
                    onChange={(e) => setDetailName(e.target.value)}
                    fullWidth
                    required
                />

                {/* Detail Description */}
                <TextField
                    label="Description"
                    value={detailDescription}
                    onChange={(e) => setDetailDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                />

                {/* Detail Icon */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Icon:</Typography>
                    <IconPicker
                        value={detailIcon}
                        onChange={(newIcon) => setDetailIcon(newIcon)}
                        theme="dark"
                        isMulti={false}
                        searchPlaceholder="Search Icons"
                    />
                </Box>

                {/* Notification Types */}
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={detailHasWeb}
                                onChange={(e) =>  (e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Enable Web Notification"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={detailHasSms}
                                onChange={(e) => setDetailHasSms(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Enable SMS Notification"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={detailHasEmail}
                                onChange={(e) => setDetailHasEmail(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Enable Email Notification"
                    />
                </Box>
            </Box>
        </Box>
    );
};

NotificationEditorDetails.propTypes = {
    detailName: PropTypes.string.isRequired,
    setDetailName: PropTypes.func.isRequired,
    detailDescription: PropTypes.string.isRequired,
    setDetailDescription: PropTypes.func.isRequired,
    detailIcon: PropTypes.string.isRequired,
    setDetailIcon: PropTypes.func.isRequired,
    detailHasWeb: PropTypes.bool.isRequired,
    setDetailHasWeb: PropTypes.func.isRequired,
    detailHasSms: PropTypes.bool.isRequired,
    setDetailHasSms: PropTypes.func.isRequired,
    detailHasEmail: PropTypes.bool.isRequired,
    setDetailHasEmail: PropTypes.func.isRequired,
};

export default NotificationEditorDetails;
