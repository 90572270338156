import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const AppointmentSelector = ({ universityID, containerLocationID, onSelect }) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {

        console.log("University ID: ", universityID, " and container: ", containerLocationID);
        const appointmentCollection = collection(
          db,
          `universities/${universityID}/containerLocations/${containerLocationID}/appointments`
        );
        const snapshot = await getDocs(appointmentCollection);

        console.log("UniversityID: ", universityID, " ContainerLocation: ", containerLocationID);

        const fetchedAppointments = snapshot.docs.map((doc) => ({
          id: doc.id,
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
        }));

        console.log("Appointments found: ", fetchedAppointments);
        setAppointments(fetchedAppointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [universityID, containerLocationID]);

  const firstAppointmentDate = appointments.length
    ? new Date(Math.min(...appointments.map((app) => app.start.getTime())))
    : null;

  const firstAppointmentMonth = firstAppointmentDate
    ? firstAppointmentDate.toLocaleString('default', { month: 'long', year: 'numeric' }).toUpperCase()
    : '';

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const handleConfirmDate = () => {
    if (selectedDate) {
      const selectedAppointment = appointments.find(
        appointment => appointment.start.toDateString() === selectedDate.toDateString()
      );
      if (selectedAppointment && onSelect) {
        onSelect(selectedAppointment);
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      style={{
        padding: '20px',
        maxWidth: '900px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <style>{`
        abbr[title] {
          text-decoration: none;
        }
        .react-calendar {
          border: none;
          position: relative;
        }
        .react-calendar__tile {
          border-radius: 25%;
          height: 50px !important;
          width: 50px !important;
          margin: 4px;  
          position: relative;
        }
        .highlight-overlay {
          position: absolute;
          top: 5%;
          left: 5%;
          width: 90%;
          height: 90%;
          background-color: rgba(0, 123, 255, 0.2);
          z-index: 1;
          pointer-events: none;
          border-radius: 25%;
        }
        .react-calendar__tile--highlight {
          background-color: white !important;
          color: unset;
        }
        .react-calendar__tile--highlight .highlight-overlay {
          display: block;
        }
        .react-calendar__tile--selected { 
          background-color: #007bff !important;
          color: white !important;
        } 
        .react-calendar__tile--disabled {
          pointer-events: none;
          background-color: white !important;
          color: #ccc !important;
        }
        .react-calendar__navigation {
          display: none;
        }
        .react-calendar__month-view__days {
          margin-top: 20px;
        }
      `}</style>

      <Typography
        variant="subtitle1"
        align="center"
        gutterBottom
        sx={{ fontSize: '14px', fontWeight: 'bold', color: 'gray' }}
      >
        {firstAppointmentMonth}
      </Typography>
      <Calendar
        value={selectedDate}
        onChange={handleSelectDate}
        defaultActiveStartDate={firstAppointmentDate || new Date()}
        tileClassName={({ date }) => {
          const isHighlighted = appointments.some(
            (appointment) => appointment.start.toDateString() === date.toDateString()
          );
          const isSelected = selectedDate && selectedDate.toDateString() === date.toDateString();
          return isSelected
            ? 'react-calendar__tile--selected'
            : isHighlighted
            ? 'react-calendar__tile--highlight'
            : 'react-calendar__tile--disabled';
        }}
        tileContent={({ date }) => {
          const isHighlighted = appointments.some(
            (appointment) => appointment.start.toDateString() === date.toDateString()
          );
          return isHighlighted ? <div className="highlight-overlay"></div> : null;
        }}
        tileDisabled={({ date }) => {
          return !appointments.some(
            (appointment) => appointment.start.toDateString() === date.toDateString()
          );
        }}
        showNeighboringMonth={false}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleConfirmDate}
        disabled={!selectedDate}
        sx={{ mt: 2 }}
      >
        Confirm Date
      </Button>
    </Box>
  );
};

export default AppointmentSelector;
