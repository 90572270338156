import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Paper, CircularProgress, Button,  Avatar } from '@mui/material';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

const ItemInfo = () => { 
  const { itemId
} = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const db = getFirestore();
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);
  const [itemConfig, setItemConfig] = useState(null);
  const [allConfigs, setAllConfigs] = useState({});

  useEffect(() => {
    const loadAllConfigs = async () => {
      try {
        const itemConfigsModule = await import('../../config/items.json');
        const itemConfigs = itemConfigsModule.default || itemConfigsModule;
        if (!Array.isArray(itemConfigs)) {
          throw new TypeError('itemConfigs is not an array');
        }
        const items = {};
        itemConfigs.forEach((itemConfig) => {
          items[itemConfig.id] = itemConfig;
        });
        return items;
      } catch (error) {
        console.error('Error loading item configurations:', error);
        return {};
      }
    };

    const fetchConfigs = async () => {
      const configs = await loadAllConfigs();
      setAllConfigs(configs);
    };

    fetchConfigs();
  }, [db]);

  useEffect(() => {
    const fetchItemData = async () => {
      if (currentUser && itemId
        ) {
        try {
          const itemDoc = await getDoc(doc(db, `inventoryUsers/${currentUser.uid}/items`, itemId
          ));
          if (itemDoc.exists()) {
            const itemData = itemDoc.data();
            setItemData(itemData);
            const itemType = itemData.type;
            setItemConfig(allConfigs[itemType]);
          } else {
            navigate('/dashboard/items');
          }
        } catch (error) {
          console.error('Error fetching item data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchItemData();
  }, [currentUser, itemId
    , allConfigs, navigate, db]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!itemData) {
    return null;
  }

  return (
    <Container sx={{width: "100%"}}>
        <Box maxWidth="lg" sx={{ p: 3, flexGrow: 1 }}>
          <Paper sx={{ p: 2, backgroundColor: 'primary.main', color: 'white', textAlign: 'center', 
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat', mb: 3, pb: 4 }}>
                      <Box sx={{display: "flex", justifyContent: "space-between"}}>
                      <Button onClick={() => navigate(-1)} variant="outlined" sx={{color: "white", borderColor: "white", ml: 2}}>
                        Back
                      </Button>
                      <Button onClick={() => navigate(`/dashboard/edit-item/${itemId}`)} variant="outlined" sx={{color: "white", borderColor: "white", mr: 2}}  >
                        Edit
                      </Button>
                    </Box>
                    <Avatar
                      src={itemConfig.icon}
                      alt={itemConfig.label}
                      sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
                    />
                    <Typography variant="subtitle2" sx={{mt: 3}}> Item: </Typography>
                    <Typography variant="h5" >"{itemData.name}"</Typography>
                    
            </Paper>
            <Paper sx={{p: 3}}>
              <Typography variant="h6" sx={{mb: 2 }}>
                    Item Details
                  </Typography>
                  <Typography variant="body1">
                    <strong>Name:</strong> {itemData.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Description:</strong> {itemData.description}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Type:</strong> {itemData.type}
                  </Typography>
                  {itemData.dimensions && (
                    <Typography variant="body1">
                      <strong>Dimensions:</strong> {itemData.dimensions.length} x {itemData.dimensions.width} x {itemData.dimensions.height} inches
                    </Typography>
                  )}
                  <Typography variant="body1">
                    <strong>Supplies Included:</strong> {itemData.suppliesIncluded ? 'Yes' : 'No'}
                  </Typography>
            </Paper>
          </Box>
    </Container>
  );
};

export default ItemInfo;
